import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectUIEffects = (state: RootState) => state.uiEffects;

const selectHideProgressBar = createSelector(selectUIEffects, (uiEffects) => uiEffects.hideProgressBar);
const selectHideHeader = createSelector(selectUIEffects, (uiEffects) => uiEffects.hideHeader);
const selectAppLoader = createSelector(selectUIEffects, (uiEffects) => uiEffects.appLoader);

export {
    selectHideProgressBar,
    selectHideHeader,
    selectAppLoader,
};
