import { all } from 'redux-saga/effects';

import userSagas from './user/userSagas';
import { initPaymentGenerator } from './payment/initPaymentGenerator';
import { initBankCard } from './payment/initBankCard';
import { initPayPal } from './payment/initPayPal';
import { initSolidPayment } from './payment/initSolid';
import { validatePayment } from './payment/validatePayment';
import { initOneClickPayment } from './payment/oneClickPayment';
import { skipOnBoardingSaga } from './user/skipOnboarding';
import { autoLoginSaga } from './user/autologin';

export default function* rootSaga() {
  yield all([
    ...userSagas,
    ...initPaymentGenerator,
    ...initBankCard,
    ...initPayPal,
    ...initSolidPayment,
    ...validatePayment,
    ...initOneClickPayment,
    ...skipOnBoardingSaga,
    ...autoLoginSaga,
  ]);
}
