import AbstractPayment from '../abstractPayment';

class ApplePay extends AbstractPayment {
    constructor() {
        super('applePay');
    }

    async initPayment(payload: any) {
        console.log('apple payload', payload);
        if (this.applePayAvailable()) {
            const config = {
                countryCode: 'US',
                currencyCode: 'USD',
                merchantCapabilities: ['supports3DS'],
                supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
                total: {
                    label: 'MyCoach',
                    type: 'final',
                    amount: payload.price,
                },
            };
            this.startApplePaySession(config, payload);
        }

        console.error('Available only in Safari.');
    }

    startApplePaySession(config: any, payload: any) {
        console.log('start apple pay session', config, payload);
        // @ts-ignore
        const applePaySession = new ApplePaySession(6, config);
        this.handleApplePayEvents(applePaySession, payload);
        applePaySession.begin();
        console.log('34 start apple pay session', applePaySession);
    }

    applePayAvailable() {
        // @ts-ignore
        return window.ApplePaySession && ApplePaySession.canMakePayments();
    }

    async validateAppleMerchant(event: any) {
        const data = await super.validateApplePayMerchant({validationURL: event.validationURL});
        console.log('validate applemerchant data - ', data);
        
return data;
    }

    handleApplePayEvents(appleSession: any, payload: any) {
        // This is the first event that Apple triggers. Here you need to validate the
        // Apple Pay Session from your Back-End
        appleSession.onvalidatemerchant = async (event: any) => {
            const validationResponse: any = await super.validateApplePayMerchant({validationURL: event.validationURL});

            console.log('handle apple pay events, ', validationResponse);
            if (validationResponse) {
                console.log('56 validation response ');
                appleSession.completeMerchantValidation(validationResponse);
            }
        };
        appleSession.onpaymentauthorized = (event: any) => {
            const paymentData = event.payment.token.paymentData;
            console.log('payment data 62,', paymentData);
            const meta = Object.assign(payload, paymentData);
            console.log('--------', meta);
            super.initPayment(meta)
                .then((response) => {
                    // @ts-ignore
                    appleSession.completePayment(ApplePaySession.STATUS_SUCCESS);

                    payload.successHandler(response);
                })
                .catch((error) => {
                    // @ts-ignore
                    appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
                    console.error(error);
                });
        };
    }

}

export default ApplePay;
