import AbstractPayment from '../abstractPayment';

// constants
import { PAYMENT_GENERATOR } from '../../constants';

// api
import api from '../../apiSingleton';

// types
import {InitPaymentGenerator} from '../../interfaces/payment';

class PaymentGenerator extends AbstractPayment {
    constructor() {
        super(PAYMENT_GENERATOR);
    }

    public async initPayment(payload: InitPaymentGenerator) {
        try {
            const normalizedData = await super.normalizeInitData(payload);
            const merchantData: any = await api.payment.getPaymentIntentWithSignature({
                ...normalizedData
            });

            return {
                paymentIntent: merchantData.payment_intent,
                merchant: merchantData.merchant,
                signature: merchantData.signature
            };
        } catch (e) {
            throw new Error('In initPayment method something going wrong ;(');
        }
    }
}

export default PaymentGenerator;
