let prevStep = 0;

export const useMarkedHeader = (progress: number, dots: number) => {
  if (progress === 1) {
    return {
      step: dots,
      dots,
    };
  }

  const step = Math.floor(progress / (1 / (dots - 1)));
  const isNextStep = step === prevStep + 1;
  prevStep = step;

  if (isNextStep) {
    return {
      step,
      dots,
    };
  }

  return {
    step,
    dots,
  };
};
