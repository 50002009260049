import { Extras, errorType, Tags, IError } from './interfaces';

import { SENTRY_AXIOS } from 'constants/sentry';

import { objectEntriesPolyfill } from 'helpers/polyfills';

export const getCustomTags = (error: IError, type: errorType, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};
    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return objectEntriesPolyfill(customTags);
};
