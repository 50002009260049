import PayPal from './paymentSystems/payPal';
import BankCard from './paymentSystems/bankCard';
import AbstractPayment from './abstractPayment';
import ApplePay from './paymentSystems/applePay';
import PaymentGenerator from './paymentSystems/paymentGenerator';

class StrategyManager {
    private state: any;
    constructor() {
        this.state = {
            systems: [new BankCard(),new PayPal(), new ApplePay(), new PaymentGenerator()]
        };
    }

    async getStrategy(systemName: string): Promise<AbstractPayment> {
        for await (const system of this.state.systems) {
            if(await system.isSupport(systemName)){
                return system;
            }
        }

        throw new Error(`${systemName} - 'no such class exists`);
    }

}

export default StrategyManager;
