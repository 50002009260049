import { Provider } from 'wikr-core-analytics';

import sentry from 'services/Sentry';

import { getDeviceOS } from 'helpers/utilsUpdated';

import { setPlatform } from 'redux/User/actions';

import { Dispatch } from 'types/commonInterfaces';

import { ERROR_LEVELS, SENTRY_ANALYTICS } from 'constants/sentry';

const getResolveParams = async (value: unknown) => {
  return new Promise((resolve) => {
    resolve(value);
  });
};

export const setAnalyticData = async ({ country, abTestName, urlParams, userId }: any) => {
  if (userId) {
    await Provider.setUserId(Number(userId));
  }

  await Provider.provideData({
    country: async () => await getResolveParams(country),
    abTestName: async () => await getResolveParams(abTestName),
    urlParams: async () => await getResolveParams(urlParams),
    clientHandlerFn: (error: Error) => sentry.logError(error, SENTRY_ANALYTICS, ERROR_LEVELS.ERROR, {}),
  });
};

export const setUserPlatform = (dispatch: Dispatch) => {
  const platform = getDeviceOS();

  dispatch(setPlatform(platform));
};
