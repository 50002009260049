export const SIGN_UP = 'USER/SIGN_UP';
export const SIGN_UP_SUCCESS = 'USER/SIGN_UP_SUCCESS';
export const SET_USER_TOKEN = 'USER/SET_USER_TOKEN';
export const UPDATE_INFO = 'USER/UPDATE_INFO';
export const UPDATE_INFO_FAIL = 'USER/UPDATE_INFO_FAIL';
export const SET_USER_ID = 'USER/SET_USER_ID';
export const SET_EMAIL = 'USER/SET_EMAIL';
export const SET_EMAIL_ERROR = 'USER/SET_EMAIL_ERROR';

export const SET_EMAIL_SUCCESS = 'USER/SET_EMAIL_SUCCESS';
export const SET_USER_DATA = 'USER/SET_USER_DATA';
export const SET_COUNTRY = 'USER/SET_COUNTRY';
export const SET_IS_PAID = 'USER/SET_IS_PAID';

export const UPDATE_MARKETING_MAILING = 'UPDATE_MARKETING_MAILING';

export const SET_PLATFORM = 'USER/SET_PLATFORM';
export const SET_IS_LOADING = 'USER/SET_IS_LOADING';
export const UPDATE_FLOW = 'USER/UPDATE_FLOW';
export const SET_IS_AUTOLOGIN_COMPLETE = 'SET_IS_AUTOLOGIN_COMPLETE';
