export default [
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age',
  },
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age-routine',
  },
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age-gp',
  },
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age-gp-sex',
  },
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age-gp-fast',
  },
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age-gp-cant',
  },
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age-gp-chances',
  },
  {
    path: 'OnboardingPages/ObIntroAge',
    route: 'ob-intro-age-gp-egg',
  },
  {
    path: 'OnboardingPages/ObIntroAgeGpReal',
    route: 'ob-intro-age-gp-real',
  },
  {
    path: 'OnboardingPages/ObIntroAgeSex',
    route: 'ob-intro-age-sex',
  },
  {
    path: 'OnboardingPages/ObIntroAgeSexNew',
    route: 'ob-intro-age-sex-new',
  },
  {
    path: 'OnboardingPages/ObIntroAgeLoadWo',
    route: 'ob-intro-age-load-wo',
  },
  {
    path: 'OnboardingPages/ObIntroAgeLoadDr',
    route: 'ob-intro-age-load-dr',
  },
  {
    path: 'OnboardingPages/ObIntroValueprops',
    route: 'ob-intro-valueprops',
  },
  {
    path: 'OnboardingPages/ObIntroAnimation',
    route: 'ob-intro-animation',
  },
  {
    path: 'OnboardingPages/ObUserGoalVertical',
    route: 'ob-user-goal-vertical',
  },
  {
    path: 'OnboardingPages/ObGpExplanation',
    route: 'ob-gp-explanation',
  },
  {
    path: 'OnboardingPages/ObPrivacyPolicy',
    route: 'ob-privacy-policy',
  },
  {
    path: 'OnboardingPages/ObPeriodDuration',
    route: 'ob-period-duration',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-mood-swings',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-reproductive-disorders',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-doctor-checkup',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-mental-health',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-sex-issues',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-interesting-topics-tc',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-interesting-topics-gp',
  },
  {
    path: 'OnboardingPages/ObCycleRegularity',
    route: 'ob-cycle-regularity',
  },
  {
    path: 'OnboardingPages/ObCycleDuration',
    route: 'ob-cycle-duration',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-health-problems',
  },
  {
    path: 'OnboardingPages/ObPlanCreation',
    route: 'ob-plan-creation',
  },
  {
    path: 'OnboardingPages/ObPlanCreationSteps',
    route: 'ob-plan-creation-steps',
  },
  {
    path: 'OnboardingPages/ObEnterEmail',
    route: 'ob-enter-email',
  },
  {
    path: 'OnboardingPages/ObEmailMarketing',
    route: 'ob-email-marketing',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-prenatal-supplements',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-experienced-symptoms',
  },
  {
    path: 'GeneralPages/Restrictions',
    route: 'ob-restrictions',
  },
  {
    path: 'GeneralPages/PaymentFailed',
    route: 'payment-failed',
  },
  {
    path: 'OnboardingPages/ObBirthYear',
    route: 'ob-birth-year',
  },
  {
    path: 'OnboardingPages/ObBirthYear',
    route: 'ob-birth-year-sex',
  },
  {
    path: 'OnboardingPages/ObBirthYear',
    route: 'ob-pg-birth-year',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-first-pregnancy',
  },
  {
    path: 'OnboardingPages/ObLifestyleAdjustment',
    route: 'ob-lifestyle-adjustment',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-job-type',
  },
  {
    path: 'OnboardingPages/ObConceptionLength',
    route: 'ob-conception-length',
  },
  {
    path: 'OnboardingPages/ObOvulationCalculation',
    route: 'ob-ovulation-calculation',
  },
  {
    path: 'OnboardingPages/ObIntroGp',
    route: 'ob-intro-gp',
  },
  {
    path: 'OnboardingPages/ObIntroSocialProof',
    route: 'ob-social-proof',
  },
  {
    path: 'OnboardingPages/ObIntroSocialProofFlow',
    route: 'ob-social-proof-flow',
  },
  {
    path: 'OnboardingPages/ObIntroTc',
    route: 'ob-intro-tc',
  },
  {
    path: 'OnboardingPages/ObBirthControl',
    route: 'ob-birth-control',
  },
  {
    path: 'GeneralPages/Registration',
    route: 'registration-page',
  },
  {
    path: 'PaymentLandingPages/PaymentNew',
    route: 'payment',
  },
  {
    path: 'UpsellPages/UpsellPlanner',
    route: 'upsell-main',
  },
  {
    path: 'UpsellPages/UpsellPlanner',
    route: 'upsell-discount',
  },
  {
    path: 'UpsellPages/UpsellPlannerAIMiddle',
    route: 'upsell-ai-middle',
  },
  {
    path: 'UpsellPages/UpsellPlannerAIMiddle',
    route: 'upsell-ai-middle-discount',
  },
  {
    path: 'UpsellPages/UpsellPlanner',
    route: 'upsell-main-payment',
  },
  {
    path: 'UpsellPages/UpsellPlanner',
    route: 'upsell-main-payment-discount',
  },
  {
    path: 'UpsellPages/UpsellPlannerAI',
    route: 'upsell-ai',
  },
  {
    path: 'UpsellPages/UpsellPlannerAI',
    route: 'upsell-ai-discounted',
  },
  {
    path: 'PaymentLandingPages/PaymentIntro',
    route: 'payment-intro',
  },
  {
    path: 'PaymentLandingPages/PaymentIntroDiscount',
    route: 'payment-intro-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentTimeline',
    route: 'payment-timeline',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineDiscount',
    route: 'payment-timeline-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentTimeline3m',
    route: 'payment-timeline-3m',
  },
  {
    path: 'PaymentLandingPages/PaymentTimeline3mDiscount',
    route: 'payment-timeline-3m-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentWanted',
    route: 'payment-wanted',
  },
  {
    path: 'PaymentLandingPages/PaymentWantedDiscount',
    route: 'payment-wanted-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentWanted',
    route: 'payment-wanted-3M',
  },
  {
    path: 'PaymentLandingPages/PaymentWantedDiscount',
    route: 'payment-wanted-3M-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentWantedDiscount',
    route: 'payment-wanted-extradiscount',
  },
  {
    path: 'PaymentLandingPages/PaymentWantedTest',
    route: 'payment-wanted-test',
  },
  {
    path: 'PaymentLandingPages/PaymentWantedDiscountTest',
    route: 'payment-wanted-discount-test',
  },
  {
    path: 'PaymentLandingPages/PaymentTimeline',
    route: 'payment-timeline-mp',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineDiscount',
    route: 'payment-timeline-mp-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentTimeline',
    route: 'payment-timeline-mp-1m',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineDiscount',
    route: 'payment-timeline-mp-1m-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineMpT1',
    route: 'payment-timeline-mp-t1',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineMpT1Discount',
    route: 'payment-timeline-mp-discount-t1',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineMpUpdcho',
    route: 'payment-timeline-mp-updcho',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineMpUpdchoDiscount',
    route: 'payment-timeline-mp-discount-updcho',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineMpUpdcho',
    route: 'payment-timeline-6m',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineMpUpdchoDiscount',
    route: 'payment-timeline-6m-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineLatam',
    route: 'payment-timeline-latam',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineLatamDiscount',
    route: 'payment-timeline-latam-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentPg',
    route: 'payment-pg',
  },
  {
    path: 'PaymentLandingPages/PaymentPgDiscounted',
    route: 'payment-pg-discounted',
  },
  {
    path: 'PaymentLandingPages/PaymentChIntegrated',
    route: 'payment-ch-integrated',
  },
  {
    path: 'PaymentLandingPages/PaymentTimelineDiscount',
    route: 'payment-timeline-extradiscount',
  },
  {
    path: 'PaymentLandingPages/PaymentIntroLv',
    route: 'payment-intro-lv',
  },
  {
    path: 'PaymentLandingPages/PaymentIntroLvDiscount',
    route: 'payment-intro-lv-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentIntroTc',
    route: 'payment-intro-tc',
  },
  {
    path: 'PaymentLandingPages/PaymentIntroTcDiscount',
    route: 'payment-intro-tc-discount',
  },
  {
    path: 'PaymentLandingPages/PaymentIntro',
    route: 'payment-intro-hp',
  },
  {
    path: 'PaymentLandingPages/PaymentIntroDiscount',
    route: 'payment-intro-hp-discount',
  },
  {
    path: 'GeneralPages/ThankYou',
    route: 'thank-you',
  },
  {
    path: 'OnboardingPages/ObHeight',
    route: 'ob-height',
  },
  {
    path: 'OnboardingPages/ObWeight',
    route: 'ob-weight',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-cramps',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-job-description',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-how-long-cycle',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-ever-birth-control',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-children-number-v2',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-back-pain',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-sleep',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-business',
  },
  {
    path: 'OnboardingPages/ObBestGraph',
    route: 'ob-best-graph',
  },
  {
    path: 'OnboardingPages/ObIntroAnimation',
    route: 'ob-intro-animation-ai',
  },
  {
    path: 'OnboardingPages/ObIntroAnimation',
    route: 'ob-intro-animation-trust',
  },
  {
    path: 'OnboardingPages/ObFirstLog',
    route: 'ob-first-log',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-feelings',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-when-to-conceive',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-diet',
  },
  {
    path: 'OnboardingPages/ObSymptomsActual',
    route: 'ob-symptoms-actual',
  },
  {
    path: 'OnboardingPages/ObExamining',
    route: 'ob-examining',
  },
  {
    path: 'OnboardingPages/ObHealthCheck',
    route: 'ob-health-check',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-did-you-know',
  },
  {
    path: 'OnboardingPages/ObBodySignals',
    route: 'ob-body-signals',
  },
  {
    path: 'OnboardingPages/ObChooseUs',
    route: 'ob-choose-us',
  },
  {
    path: 'OnboardingPages/ObChooseUsSex',
    route: 'ob-choose-us-sex',
  },
  {
    path: 'OnboardingPages/ObConfiding',
    route: 'ob-confiding',
  },
  {
    path: 'OnboardingPages/ObFertilityTarget',
    route: 'ob-fertility-target',
  },
  {
    path: 'OnboardingPages/ObBoostChart',
    route: 'ob-boost-chart',
  },
  {
    path: 'OnboardingPages/ObDoctorComment',
    route: 'ob-doctor-comment',
  },
  {
    path: 'OnboardingPages/ObPlanCreationBP',
    route: 'ob-plan-creation-bp',
  },
  {
    path: 'OnboardingPages/ObPregnancySupport',
    route: 'ob-pregnancy-support',
  },
  {
    path: 'OnboardingPages/ObBeforeAfter',
    route: 'ob-before-after',
  },
  {
    path: 'OnboardingPages/ObWelcome',
    route: 'ob-welcome',
  },
  {
    path: 'OnboardingPages/ObSexStart',
    route: 'ob-sex-start',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-describe-pleasure',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-different-orgasms',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-kegel',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-orgasm-overthinking',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-sexual-pleasure',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-body-connection',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-partner-connection',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-changes-intimacy',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-blockers',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-feel-best',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-cycle-sex',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-sex-drive',
  },
  {
    path: 'OnboardingPages/ObOrgasmsComeback',
    route: 'ob-orgasms-comeback',
  },
  {
    path: 'OnboardingPages/ObOrgasmBenefits',
    route: 'ob-orgasm-benefits',
  },
  {
    path: 'OnboardingPages/ObBoostPleasure',
    route: 'ob-boost-pleasure',
  },
  {
    path: 'OnboardingPages/ObSexGraph',
    route: 'ob-sex-graph',
  },
  {
    path: 'OnboardingPages/ObPatterns',
    route: 'ob-patterns',
  },
  {
    path: 'OnboardingPages/ObGoalSex',
    route: 'ob-goal-sex',
  },
  {
    path: 'OnboardingPages/ObGoalSexNew',
    route: 'ob-goal-sex-new',
  },
  {
    path: 'OnboardingPages/ObPlanCreationSteps',
    route: 'ob-plan-creation-steps-lv',
  },
  {
    path: 'OnboardingPages/ObEnterEmail',
    route: 'ob-enter-email-sex',
  },
  {
    path: 'OnboardingPages/ObEnterEmail',
    route: 'ob-enter-email-sex-new',
  },
  {
    path: 'OnboardingPages/ObEnterEmail',
    route: 'ob-pg-email',
  },
  {
    path: 'OnboardingPages/ObGoalGp',
    route: 'ob-goal-gp',
  },
  {
    path: 'OnboardingPages/ObGoalGeneral',
    route: 'ob-goal-general',
  },
  {
    path: 'OnboardingPages/ObIntroGpFlow',
    route: 'ob-intro-gp-flow',
  },
  {
    path: 'OnboardingPages/ObIntroTcFlow',
    route: 'ob-intro-tc-flow',
  },
  {
    path: 'OnboardingPages/ObChooseUsFlow',
    route: 'ob-choose-us-flow',
  },
  {
    path: 'OnboardingPages/ObDoctorCommentTc',
    route: 'ob-doctor-comment-tc',
  },
  {
    path: 'OnboardingPages/ObIntroSocialProofFlowTc',
    route: 'ob-social-proof-flow-tc',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-gn-how-conceive',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-gn-health-issues',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-given-birth',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-why',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-priorities',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-food-relationships',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-typical-day',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-miscarriage',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-pcos',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-sti',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-chemicals',
  },
  {
    path: 'OnboardingPages/ObGnUnlock',
    route: 'ob-gn-unlock',
  },
  {
    path: 'OnboardingPages/ObGnHalfWay',
    route: 'ob-gn-half-way',
  },
  {
    path: 'OnboardingPages/ObGnEmail',
    route: 'ob-gn-email',
  },
  {
    path: 'OnboardingPages/ObGnReviewFirst',
    route: 'ob-gn-review-first',
  },
  {
    path: 'OnboardingPages/ObGnChart',
    route: 'ob-gn-chart',
  },
  {
    path: 'OnboardingPages/ObGnLoading',
    route: 'ob-gn-loading',
  },
  {
    path: 'OnboardingPages/ObGnIntro',
    route: 'ob-gn-intro',
  },
  {
    path: 'OnboardingPages/ObGnLongpage',
    route: 'ob-gn-longpage',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-help',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-triggers',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-eating-habits',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-state-of-mind',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-period-duration',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-hormonal-balance',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-dietery-restrictions',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-physical-limitation',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-interrelated',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-statement-which',
  },
  {
    path: 'OnboardingPages/ObGnStatementPartner',
    route: 'ob-gn-statement-partner',
  },
  {
    path: 'OnboardingPages/ObGnStatementMotivation',
    route: 'ob-gn-statement-motivation',
  },
  {
    path: 'OnboardingPages/ObGnTogether',
    route: 'ob-gn-together',
  },
  {
    path: 'OnboardingPages/ObGnHbanswer',
    route: 'ob-gn-hbanswer',
  },
  {
    path: 'OnboardingPages/ObGnBeyonce',
    route: 'ob-gn-beyonce',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-gn-life-changes',
  },
  {
    path: 'OnboardingPages/ObGnGoodHands',
    route: 'ob-gn-good-hands',
  },
  {
    path: 'OnboardingPages/ObGnLongAnim',
    route: 'ob-gn-long-anim',
  },
  {
    path: 'OnboardingPages/ObGnConfidence',
    route: 'ob-gn-confidence',
  },
  {
    path: 'OnboardingPages/ObGnUncertain',
    route: 'ob-gn-uncertain',
  },
  {
    path: 'OnboardingPages/ObGnWeAreGlad',
    route: 'ob-gn-we-are-glad',
  },
  {
    path: 'OnboardingPages/ObGnGpfaster1',
    route: 'ob-gn-gpfaster-1',
  },
  {
    path: 'OnboardingPages/ObGnGpfaster2',
    route: 'ob-gn-gpfaster-2',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-pace',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-meal-prep',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-birth-control',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-fitness-tracker',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-gn-services',
  },
  {
    path: 'OnboardingPages/ObGnJustAWish',
    route: 'ob-gn-just-a-wish',
  },
  {
    path: 'OnboardingPages/ObGnNotEnough',
    route: 'ob-gn-not-enough',
  },
  {
    path: 'OnboardingPages/ObPrepayment',
    route: 'ob-prepayment',
  },
  {
    path: 'OnboardingPages/ObGnPlanCreating',
    route: 'ob-gn-plan-creating',
  },
  {
    path: 'OnboardingPages/ObGnArticles',
    route: 'ob-gn-articles',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-energy-levels',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-mood-emotions',
  },
  {
    path: 'OnboardingPages/ObChooseUsCleanse',
    route: 'ob-choose-us-cleanse',
  },
  {
    path: 'OnboardingPages/ObChooseUsReal',
    route: 'ob-choose-us-real',
  },
  {
    path: 'OnboardingPages/ObChooseUsCleanseStart',
    route: 'ob-choose-us-cleanse-start',
  },
  {
    path: 'OnboardingPages/ObGoalGpNew',
    route: 'ob-goal-gp-new',
  },
  {
    path: 'OnboardingPages/ObSaveMoneyChart',
    route: 'ob-save-money-chart',
  },
  {
    path: 'OnboardingPages/ObBlockEggs',
    route: 'ob-block-eggs',
  },
  {
    path: 'OnboardingPages/ObBlockIntimacy',
    route: 'ob-block-intimacy',
  },
  {
    path: 'OnboardingPages/ObBlockHormones',
    route: 'ob-block-hormones',
  },
  {
    path: 'OnboardingPages/ObBlockPsych',
    route: 'ob-block-psych',
  },
  {
    path: 'OnboardingPages/ObEggsState',
    route: 'ob-eggs-state',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-feel-best-new',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-cycle-sex-new',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-initiate-sex',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-feel-loved',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-quest-desire',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-3-month-desire',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-month-desire',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-sex-satisfaction',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-hard-orgasm',
  },
  {
    path: 'OnboardingPages/ObPartnerTrust',
    route: 'ob-partner-trust',
  },
  {
    path: 'OnboardingPages/ObSexState',
    route: 'ob-sex-state',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-birth-control-new',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-think-about-look',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-sex-over-fun',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-sex-relax',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-tired-to-sex',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-sex-confidence',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-sex-mood',
  },
  {
    path: 'OnboardingPages/ObBlockProfile',
    route: 'ob-block-profile',
  },
  {
    path: 'OnboardingPages/ObBlockMind',
    route: 'ob-block-mind',
  },
  {
    path: 'OnboardingPages/ObBlockRelation',
    route: 'ob-block-relation',
  },
  {
    path: 'OnboardingPages/ObBlockSexDrive',
    route: 'ob-block-sex-drive',
  },
  {
    path: 'OnboardingPages/ObBodyLang',
    route: 'ob-body-lang',
  },
  {
    path: 'OnboardingPages/ObCoupleBond',
    route: 'ob-couple-bond',
  },
  {
    path: 'OnboardingPages/ObImproveSex',
    route: 'ob-improve-sex',
  },
  {
    path: 'OnboardingPages/ObVibrantSex',
    route: 'ob-vibrant-sex',
  },
  {
    path: 'OnboardingPages/ObBoostPleasureNew',
    route: 'ob-boost-pleasure-new',
  },
  {
    path: 'OnboardingPages/ObPlanCreationStepsLvNew',
    route: 'ob-plan-creation-steps-lv-new',
  },
  {
    path: 'OnboardingPages/ObSelectCeleb',
    route: 'ob-select-celeb',
  },
  {
    path: 'OnboardingPages/ObTopObgyns',
    route: 'ob-top-obgyns',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-are-you-from-doctor',
  },
  {
    path: 'OnboardingPages/ObResultsBlockers',
    route: 'ob-results-blockers',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-feel-good',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-solo-play',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-solo-music',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-self-pleasure',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-solo-empowered',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-desire-question',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-kinky-me',
  },
  {
    path: 'OnboardingPages/ObSexTechThird',
    route: 'ob-sex-tech-third',
  },
  {
    path: 'OnboardingPages/ObSexTechFourth',
    route: 'ob-sex-tech-fourth',
  },
  {
    path: 'OnboardingPages/ObSexTechFirst',
    route: 'ob-sex-tech-first',
  },
  {
    path: 'OnboardingPages/ObSexTechSecond',
    route: 'ob-sex-tech-second',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-sex-talk',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-relation-improve',
  },
  {
    path: 'OnboardingPages/ObPleasureGoal',
    route: 'ob-pleasure-goal',
  },
  {
    path: 'OnboardingPages/ObSelfAware',
    route: 'ob-self-aware',
  },
  {
    path: 'OnboardingPages/ObBlockSexmind',
    route: 'ob-block-sexmind',
  },
  {
    path: 'OnboardingPages/ObBlockPleasure',
    route: 'ob-block-pleasure',
  },
  {
    path: 'OnboardingPages/ObBlockReward',
    route: 'ob-block-reward',
  },
  {
    path: 'OnboardingPages/ObMenoEmotions',
    route: 'ob-meno-emotions',
  },
  {
    path: 'OnboardingPages/ObMenoSymptoms',
    route: 'ob-meno-symptoms',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-relationships',
  },
  {
    path: 'OnboardingPages/ObSexMenstruation',
    route: 'ob-sex-menstruation',
  },
  {
    path: 'OnboardingPages/ObSexExpert',
    route: 'ob-sex-expert',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-sex-pregnant',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-try-techniques',
  },
  {
    path: 'OnboardingPages/ObMenoGraph',
    route: 'ob-meno-graph',
  },
  {
    path: 'OnboardingPages/ObPgChooseUs',
    route: 'ob-pg-choose-us',
  },
  {
    path: 'OnboardingPages/ObPgExperts',
    route: 'ob-pg-experts',
  },
  {
    path: 'OnboardingPages/ObPgBabyExploration',
    route: 'ob-pg-baby-exploration',
  },
  {
    path: 'OnboardingPages/ObPgAdmire',
    route: 'ob-pg-admire',
  },
  {
    path: 'OnboardingPages/ObPgBabyMind',
    route: 'ob-pg-baby-mind',
  },
  {
    path: 'OnboardingPages/ObPgThHonest',
    route: 'ob-pg-th-honest',
  },
  {
    path: 'OnboardingPages/ObPgQuestionAnswered',
    route: 'ob-pg-question-answered',
  },
  {
    path: 'OnboardingPages/ObPgPlanCreation',
    route: 'ob-pg-plan-creation',
  },
  {
    path: 'OnboardingPages/ObPgBeforeAfter',
    route: 'ob-pg-before-after',
  },
  {
    path: 'OnboardingPages/ObIntroAgePg',
    route: 'ob-intro-age-pg',
  },
  {
    path: 'OnboardingPages/ObPgGoal',
    route: 'ob-pg-goal',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-first-time',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-feelings',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-fertility-treatment',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-ttc',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-back-pain',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-baby-movements',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-adjusting-routines',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-chemical-free',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-diet',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-appetite',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-fish-meat',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-hormonal-nutrition',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-energy-levels',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-business',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-mood-emotions',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-skin',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-bloating',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-sleep',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-sleep-position',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-hair',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-anxious',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-brain-fog',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-forgetful',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-sex-drive',
  },
  {
    path: 'OnboardingPages/ObEmojiSingleGeneric',
    route: 'ob-pg-connected',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-pg-are-you-from-doctor',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-pg-mental-health',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-pg-sex-improve',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-pg-birth-control',
  },
  {
    path: 'OnboardingPages/ObMultipleGeneric',
    route: 'ob-pg-new-symptoms',
  },
  {
    path: 'OnboardingPages/ObPgQuickCheckup',
    route: 'ob-pg-quick-checkup',
  },
  {
    path: 'OnboardingPages/ObPgDuedateCalculator',
    route: 'ob-pg-duedate-calculator',
  },
  {
    path: 'OnboardingPages/ObYourName',
    route: 'ob-your-name',
  },
  {
    path: 'OnboardingPages/ObCommitmentWelcome',
    route: 'ob-commitment-welcome',
  },
  {
    path: 'OnboardingPages/ObBioAgeIntro',
    route: 'ob-bio-age-intro',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-bio-age-weight',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-bio-age-mood',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-bio-age-hair',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-bio-age-energy',
  },
  {
    path: 'OnboardingPages/ObSingleGeneric',
    route: 'ob-bio-age-diabetes',
  },
];
