import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { validatePayment } from 'redux/Payment/actions';

export function useValidatePayment() {
  const dispatch = useDispatch();
  const location = useLocation();

  return (system: string, paymentMethod: number, data: any) => {
    const payload = {
      system,
      paymentMethod,
      data,
      screenId: location.pathname,
    };
    dispatch(validatePayment(payload));
  };
}
