export const setOptions = async (options: any, constans: any) => {
    return Object.assign(constans, options);
};

export const normalizeObject = async (normalizeExample: any, payload: any) => {
    let normalizeData = {};

    for await (const [key, value] of Object.entries(payload)) {
        normalizeExample.forEach((subKey: any) => {
            if (key === subKey) {
                normalizeData = {
                    ...normalizeData,
                    [key]: value
                };
            } else if(key === 'id' && payload.payment_type === 'subscription') {
                normalizeData = {
                    ...normalizeData,
                    ['product_id']: value
                };
            }
        });
    }

    return normalizeData;
};

export const toUnderScore = async (currentProduct: any) => {
    const newObject = {};
    const camelToUnderscore = async (key: any) => {
        return key.replace(/([A-Z])/g, '_$1').toLowerCase();
    };

    for (const camel in currentProduct) {
        // @ts-ignore
        newObject[await camelToUnderscore(camel)] = currentProduct[camel];
    }
    
return newObject;
};

export const pastSolidScript = () => {
    const scriptId = 'solid-bank-card';
    if (document.getElementById(scriptId)) return;

    const { head } = document;
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.src = 'https://cdn.solidgate.com/js/solid-form.js';
    head.insertBefore(script, head.firstChild);
};

export const generateHash = async () => (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
);
