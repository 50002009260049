import React, { ReactNode } from 'react';
import { Box } from 'libComponents';
import cx from 'classnames';

import styles from './WrapperMain.module.css';

interface IWrapperMainProps {
    children: ReactNode;
    paddingX?: number;
    paddingTop?: number;
    paddingBottom?: number;
    className?: string;
}

const WrapperMain = ({ children, className, paddingX = 16, paddingTop = 40, paddingBottom = 128 }: IWrapperMainProps) => (
    <Box paddingX={paddingX} className={cx(styles.container, className)} paddingTop={paddingTop} paddingBottom={paddingBottom}>
        { children }
    </Box>
);

export default WrapperMain;
