import * as actionTypes from './actionTypes';

// interfaces
import { UiEffectStore } from 'types/uiEffects/uiEffectStore';
import { InferValueTypes } from 'types/commonInterfaces';

// actions
import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: UiEffectStore = {
    hideProgressBar: false,
    hideHeader: false,
    appLoader: false,
};

const UiEffects = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.HIDE_PROGRESS_BAR: {
            return { ...state, hideProgressBar: action.payload };
        }

        case actionTypes.HIDE_HEADER: {
            return { ...state, hideHeader: action.payload };
        }

        case actionTypes.SET_LOADER_STATE: {
            return { ...state, appLoader: action.payload };
        }

        default:
            return state;
    }
};

export default UiEffects;
