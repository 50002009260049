import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import ModalClose from '../ModalClose/ModalClose';

import modalStyles from './Modal.module.css';
import modalLightStyles from './ModalLight.module.css';
import modalWithPopoverStyles from './ModalWithPopover.module.css';

Modal.defaultProps = {
  styles: {},
  isOpen: false,
  withClose: true,
  shouldCloseOnOverlayClick: true,
  onClose: () => {
  },
};

Modal.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  isOpen: PropTypes.bool,
  withClose: PropTypes.bool,
  onClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

function Modal(props) {
  const {
    children,
    styles,
    isOpen,
    onClose,
    withClose,
    shouldCloseOnOverlayClick,
    isTransparency,
    isLeftClose,
    isExclusiveOffer,
    paddingTop32,
    paddingTop40,
    paddingTop78,
    padding26,
    top182,
    isSmallClose,
    noOverflow,
    isFixedClose,
    isFlex,
    isLightTheme,
    isPopover,
    withOpacity = false,
    isShowModal = false,
    isConfirmationModal,
  } = props;

  useEffect(() => {
    ReactModal.setAppElement('#app');
  }, []);

  useEffect(() => {
    document.body.style.overflow = isShowModal ? 'hidden' : 'auto';
  }, [isShowModal]);

  const closeModal = () => {
    if (onClose) onClose();
  };

  const classes = Object.assign(styles);
  const stylesForModal = isLightTheme ? modalLightStyles : modalStyles;
  const withPopoverStyles = isPopover ? modalWithPopoverStyles : stylesForModal;

  const cx = classNames.bind(withPopoverStyles);
  const contentStyles = cx('ReactModal__Content', 'ReactModal__Content__desktop', {
    ReactModal__Content__withTransparency: isTransparency,
    ReactModal__Content__exclusiveOffer: isExclusiveOffer,
    ReactModal__Content__padding26: padding26,
    ReactModal__Content__paddingTop32: paddingTop32,
    ReactModal__Content__paddingTop40: paddingTop40,
    ReactModal__Content__paddingTop78: paddingTop78,
    ReactModal__Content__noOverflow: noOverflow,
    ReactModal__Content__flex: isFlex,
    // ReactModal__Content__withPopover: isPopover,
    ReactModal__Content__confirmationModal: isConfirmationModal,
    ReactModal__Content__top182: top182,
  });
  const overlayStyles = cx('ReactModal__Overlay', {
    ReactModal__Overlay__isClose: withOpacity && !isShowModal,
    ReactModal__Overlay__isOpen: withOpacity && isShowModal,
    ReactModal__Overlay__withTransparency: isTransparency,
  });

  const bodyStyles = cx({
    ReactModal__Body__isClose: withOpacity && !isShowModal,
    ReactModal__Body__isOpen: withOpacity && isShowModal,
  });

  return (
    <ReactModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={classes}
      contentLabel='Modal'
      className={contentStyles}
      overlayClassName={overlayStyles}
      bodyOpenClassName={bodyStyles}
    >
      {onClose && withClose && (
        <ModalClose
          onClick={closeModal}
          isLeftClose={isLeftClose}
          isSmallClose={isSmallClose}
          isFixed={isFixedClose}
        />
      )}
      {children}
    </ReactModal>
  );
}

export default Modal;
