import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import Success from './img/Success.svg';
import Fail from './img/Fail.svg';
import styles from './Panel.module.css';

interface IPanelProps {
  type: string;
  title?: string;
  body?: ReactNode | string;
  mt?: number;
  mb?: number;
  pl?: number;
  leftIcon?: number;
  topIcon?: number;
  iconClassName?: string,
  /* Need only for icon vertical align */
  isOneLine?: boolean;
  Icon?: ReactElement;
  className?: string;
  disclaimerIcon?: string;
}

const Panel = ({
                 title,
                 body,
                 type,
                 mb,
                 mt,
                 pl,
                 leftIcon,
                 topIcon,
                 iconClassName, /* isOneLine, */
                 Icon,
                 className,
                 disclaimerIcon,
               }: IPanelProps) => {

  const getIcon = (type: string, disclaimerIcon?: string): ReactElement => {
    switch (type) {
      case 'success':
        return <Success />;
      case 'fail':
        return <Fail />;
      case 'disclaimer':
        return <span>☝️</span>;
      case 'sex-block':
        return <span>{disclaimerIcon}</span>;

      default:
        return <Success />;
    }
  };

  return (
    <div style={{ marginBottom: `${mb}px`, marginTop: `${mt}px`, paddingLeft: `${pl}px` }}
         className={`${styles.container} ${styles[type]} ${className}`}>
      <div style={{ left: `${leftIcon}px`, top: `${topIcon}px` }}
           className={classNames(styles.icon, iconClassName, {
             // [styles.iconInLine]: isOneLine,
           })}>{Icon ? Icon : getIcon(type, disclaimerIcon)}</div>
      {title && <div className={styles.title}>{title}</div>}
      {body && <div>{body}</div>}
    </div>
  );
};

export default Panel;
