import * as actionTypes from './actionTypes';

import { UserInfoResponse } from 'types/user/userApiInterface';

export function signUp(data: any) {
  return {
    type: actionTypes.SIGN_UP,
    payload: data,
  } as const;
}

export function signUpSuccess(data: Partial<UserInfoResponse>) {
  return {
    type: actionTypes.SIGN_UP_SUCCESS,
    payload: data,
  } as const;
}

export function updateMarketingMailing(payload: any) {
  return {
    type: actionTypes.UPDATE_MARKETING_MAILING,
    payload: payload,
  } as const;
}

export function setToken({ token, refreshToken, webToken }: any) {
  return {
    type: actionTypes.SET_USER_TOKEN,
    payload: { token, refreshToken, webToken },
  };
}

export function setUserData(payload: UserInfoResponse) {
  return {
    type: actionTypes.SET_USER_DATA,
    payload,
  } as const;
}

export function setIsPaid(status: boolean) {
  return {
    type: actionTypes.SET_IS_PAID,
    payload: status,
  } as const;
}

export function setCountry(country: any) {
  return {
    type: actionTypes.SET_COUNTRY,
    payload: country,
  } as const;
}

export function setPlatform(platform: string) {
  return {
    type: actionTypes.SET_PLATFORM,
    payload: platform,
  } as const;
}

export function enableIsLoading() {
  return {
    type: actionTypes.SET_IS_LOADING,
    payload: true,
  } as const;
}

export function disableIsLoading() {
  return {
    type: actionTypes.SET_IS_LOADING,
    payload: false,
  } as const;
}

export function updateUserInfo(payload: any) {
  return {
    type: actionTypes.UPDATE_INFO,
    payload: payload,
  } as const;
}

export function setUserId(id: string | number) {
  return {
    type: actionTypes.SET_USER_ID,
    payload: id,
  } as const;
}

export function setUserEmail(email: string) {
  return {
    type: actionTypes.SET_EMAIL,
    payload: email,
  } as const;
}

export function updateUserInfoFail(error: unknown) {
  return {
    type: actionTypes.UPDATE_INFO_FAIL,
    payload: error,
  } as const;
}

export function setEmailError(error: string | null) {
  return {
    type: actionTypes.SET_EMAIL_ERROR,
    payload: error,
  } as const;
}

export function updateFlow(payload: any) {
  return {
    type: actionTypes.UPDATE_FLOW,
    payload: payload,
  } as const;
}

export function setIsAutologinComplete(status: boolean) {
  return {
    type: actionTypes.SET_IS_AUTOLOGIN_COMPLETE,
    payload: status,
  } as const;
}
