import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, Button, Text } from 'libComponents';

import { useAnalytics, useScrollToTop } from 'hookExperiment';
import usePaymentFlow from 'hooks/usePaymentFlow';

import Modal from 'components/Modal';
import Checkbox from 'components/CheckBox/CheckBox';

import styles from './GuaranteeModal.module.css';

const GuaranteeModal = () => {
  useScrollToTop();
  const { customData } = useAnalytics();
  const { currentScreen } = usePaymentFlow();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!currentScreen?.config?.popup) return;

    const timeout = setTimeout(() => {
      setIsOpen(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [pathname]);

  useEffect(() => {
    if (isOpen) {
      customData('ob_pregnancy_guarantee__screen__load');
    }
  }, [isOpen]);

  const handleOnClose = () => {
    customData('ob_pregnancy_guarantee__any__click');

    setIsOpen(false);
  };

  return (
    <Modal
      withClose={false}
      // @ts-ignore
      isTransparency
      isFlex
      padding26
      paddingTop40
      isSmallClose
      isOpen
      isLightTheme
      withOpacity
      isPopover
      isShowModal={isOpen}
      onClose={handleOnClose}
    >
      <Box className={styles.container}>
        <div className={styles.topPanel} onClick={handleOnClose}>
          <div className={styles.line} />
        </div>
        <div className={styles.title}>
          <Trans>guaranteeModal.title</Trans>
        </div>

        <Text mb={12} className={styles.content}>
          <Trans i18nKey='guaranteeModal.content1' />
        </Text>

        <Text mb={24} className={styles.content}>
          <Trans i18nKey='guaranteeModal.content2' components={{
            span: <span />,
            1: <span className={styles.offer} />,
          }} />
        </Text>

        <Checkbox disabled squared checked verticalStart>
          <Text className={styles.content}>
            <Trans
              i18nKey='guaranteeModal.checkbox1.title'
              components={{
                span: <span />,
              }}
            />
          </Text>
        </Checkbox>

        <Box className={styles.lastCheck}>
          <Checkbox disabled squared checked verticalStart>
            <Text className={styles.content}>
              <Trans
                i18nKey='guaranteeModal.checkbox2.title'
                components={{
                  span: <span />,
                }}
              />
            </Text>
          </Checkbox>
        </Box>

        <Button className={styles.btnContainer} onClick={handleOnClose}
                text={t('btn.continue')} />


        {/* <ObCtaBtn onClick={handleOnClose} label={t('btn.continue')} />*/}
      </Box>
    </Modal>
  );
};

export default GuaranteeModal;
