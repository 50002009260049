import { getCookieAnalyticList, ICookiePixel } from 'constants/analytics';

export const getAnalyticInitList = (cookieIdList: string[], branchName: string | null) => {
  const analyticInitList: ICookiePixel[] = [];

  cookieIdList.forEach((cookieId) => {
    const cookieAnalytics = getCookieAnalyticList(branchName)[cookieId];

    cookieAnalytics && analyticInitList.push(...cookieAnalytics);
  });

  return analyticInitList;
};

export const getAllAnalyticInitList = (branchName: string | null) =>
  Object.values(getCookieAnalyticList(branchName)).reduce((acc, analytic) => [...acc, ...analytic], []);
