import { SeverityLevel } from '@sentry/react';

const ERROR_LEVEL_FATAL: SeverityLevel = 'fatal';
const ERROR_LEVEL_ERROR: SeverityLevel = 'error';
const ERROR_LEVEL_WARNING: SeverityLevel = 'warning';
const ERROR_LEVEL_LOG: SeverityLevel = 'log';
const ERROR_LEVEL_INFO: SeverityLevel = 'info';
const ERROR_LEVEL_DEBUG: SeverityLevel = 'debug';

const SENTRY_APP = 'APP';
const SENTRY_AXIOS = 'APP_AXIOS';
const SENTRY_ANALYTICS = 'ANALYTICS';

const SENTRY_ONE_TRUST = 'ONE_TRUST';
const SENTRY_PAYMENT = 'PAYMENTS';

const CRITICAL: SeverityLevel = 'fatal';
const ERROR: SeverityLevel = 'error';
const WARNING: SeverityLevel = 'warning';

const ERROR_LEVELS = {
    CRITICAL,
    ERROR,
    WARNING,
};


export {
    ERROR_LEVEL_FATAL,
    ERROR_LEVEL_ERROR,
    ERROR_LEVEL_WARNING,
    ERROR_LEVEL_LOG,
    ERROR_LEVEL_INFO,
    ERROR_LEVEL_DEBUG,
    SENTRY_APP,
    SENTRY_AXIOS,
    SENTRY_PAYMENT,
    ERROR_LEVELS,
    SENTRY_ONE_TRUST,
    SENTRY_ANALYTICS,
};
