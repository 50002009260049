import { gtag, install } from 'helpers/analytic/GoogleAnalytic';

import config from 'config';

const GA_CONVERSION_ID = config.GOOGLE_CONVERSION_ID;

const GA_CONVERSIONS_LABELS: Record<string, string> = {
  purchase: '6ZX5CLaqi7IZEOrWn989',
  onboarding_start: 'l502CNndjLIZEOrWn989',
  lead: 'uLITCPatkrIZEOrWn989',
  initiate_checkout: 'uc_4CNCEkrIZEOrWn989',
};

export class GoogleAnalytics {
  public name: string;
  private Google: any;
  private state: { isInit: boolean; anonymize: boolean };

  constructor() {
    this.name = 'ga';
    this.Google = gtag;
    this.state = {
      isInit: false,
      anonymize: false,
    };
  }

  init({ id }: { id: string; config?: object }) {
    try {
      install(id);
      this.state.isInit = true;
    } catch (error) {
      console.error(Error('Google init ', { cause: error }));
    }
  }

  getInit() {
    return this.state.isInit;
  }

  track(eventName: string) {
    if (!this.state.isInit) return;
    if (GA_CONVERSIONS_LABELS[eventName]) {
      const sendTo = `${GA_CONVERSION_ID}/${GA_CONVERSIONS_LABELS[eventName]}`;

      this.Google('event', 'conversion', {
        send_to: sendTo,
      });
      console.info(`%cGOOGLE CONVERSION: ${eventName} send_to: ${sendTo}`, 'color: #4285F4; font-weight: bold;');
    }
  }

  purchase(data: any) {
    if (!this.state.isInit) return;
    const { value, currency, order_id } = data;

    const sendTo = `${GA_CONVERSION_ID}/${GA_CONVERSIONS_LABELS.purchase}`;
    const conversionData = {
      send_to: sendTo,
      value: value,
      currency: currency,
      transaction_id: order_id,
    };

    this.Google('event', 'conversion', conversionData);

    console.info(`%cGOOGLE CONVERSION: purchase send_to: ${sendTo}`, 'color: #4285F4; font-weight: bold;', {
      data: conversionData,
    });
  }
}

export const GoogleAds = new GoogleAnalytics();
