import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import ICU from 'i18next-icu';

import { getCurrentLocalization, getLanguageWithCountry, getLocalizationFromAvailable } from 'helpers/localization';
import { replaceToUnderscore } from 'helpers/utilsUpdated';

import { DEFAULT_LANGUAGE, DEFAULT_NAVIGATOR_LANGUAGE } from 'constants/localization';
import { EMAIL_BRANCH, LV_BRANCH, PREGNANCY_BRANCH } from 'constants/branching';

import { getConfigBy } from 'pages/GeneralPages/InitTestania/config/helpers';

const localeFromStorage = localStorage.getItem('language');

const navigatorLanguage = getLocalizationFromAvailable();
const languageFromURL = getCurrentLocalization();

const language = navigatorLanguage || languageFromURL || localeFromStorage || DEFAULT_LANGUAGE;
const languageWithCountry = getLanguageWithCountry(language) || DEFAULT_NAVIGATOR_LANGUAGE;
const branchName = getConfigBy('branch-name');

const isLvBranchName = branchName === LV_BRANCH;
const isPregnancyBranchName = branchName === PREGNANCY_BRANCH;
const isEmailBranchName = branchName === EMAIL_BRANCH;

const isDefaultPreloadLanguage = isLvBranchName || isPregnancyBranchName || isEmailBranchName;

localStorage.setItem('language', language);

const lng = isDefaultPreloadLanguage ? DEFAULT_NAVIGATOR_LANGUAGE : languageWithCountry;
const lngForLoadPath = replaceToUnderscore(lng);

i18n
  .use(backend)
  .use(ICU)
  .use(initReactI18next)
  .init({
    lng: lng,
    fallbackLng: [DEFAULT_NAVIGATOR_LANGUAGE],
    ns: ['general', 'sex_funnel'],
    defaultNS: 'general',
    fallbackNS: 'sex_funnel',
    backend: {
      loadPath: `/locales/{{ns}}/${lngForLoadPath}/translation.json?cb=${(new Date()).getTime()}`,
    },
    preload: [DEFAULT_NAVIGATOR_LANGUAGE],
    debug: false,
    react: {
      useSuspense: false,
    },
    nsSeparator: false,
    keySeparator: false,
  });

export default i18n;
