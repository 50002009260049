import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { selectEmailError } from 'redux/User/selectors';

import { WrapperMain } from 'libComponents';

import AlertIcon from './img/Alert.svg';
import styles from './ErrorMessage.module.css';

const ErrorMessage = () => {
  const emailError = useSelector(selectEmailError);

  return (
    <>
      {emailError &&
        <WrapperMain paddingBottom={0} paddingTop={24}>
          <div className={styles.container}>
            <AlertIcon className={styles.alertIcon} />
            <div className={styles.linkContainer}>
              <Trans i18nKey={emailError as string} components={{
                a: <a href='https://femia.onelink.me/i15q' target='_blank' rel='noreferrer'>app</a>,
              }} />
            </div>
          </div>
        </WrapperMain>
      }
    </>
  );
};

export default ErrorMessage;
