import * as actionTypes from './actionTypes';
import * as action from './actions';

import { DEFAULT_COUNTRY } from 'constants/countriesList';

import { UserStore } from 'types/store/userStore';
import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: UserStore = {
  user_id: null,
  email: null,
  token: null,
  refresh_token: null,
  web_token: null,
  country: localStorage.getItem('country') || DEFAULT_COUNTRY,
  platform: null,
  isLoading: false,
  isPaid: false,
  emailError: null,
  emailSuccess: null,
  isAutologinComplete: false,
};

const UserReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SIGN_UP:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.UPDATE_MARKETING_MAILING:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SET_USER_TOKEN: {
      const { token, refreshToken, webToken } = action.payload;

      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refreshToken);
      localStorage.setItem('web_token', webToken);

      return { ...state, token, refreshToken };
    }

    case actionTypes.SET_COUNTRY:
      localStorage.setItem('country', action.payload);

      return { ...state, country: action.payload };

    case actionTypes.SET_PLATFORM:
      return { ...state, platform: action.payload };

    case actionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case actionTypes.UPDATE_INFO:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SET_USER_ID:
      return { ...state, user_id: action.payload };

    case actionTypes.SET_EMAIL:
      return { ...state, email: action.payload };

    case actionTypes.UPDATE_INFO_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case actionTypes.SET_IS_PAID:
      return { ...state, isPaid: action.payload };

    case actionTypes.SET_EMAIL_ERROR: {
      return { ...state, emailError: action.payload };
    }

    case actionTypes.SET_EMAIL_SUCCESS: {
      return { ...state, emailSuccess: action.payload };
    }

    case actionTypes.SET_IS_AUTOLOGIN_COMPLETE:
      return { ...state, isAutologinComplete: action.payload };

    default:
      return state;
  }
};

export default UserReducer;
