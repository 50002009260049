import { CurrentProduct, IPaymentFlow } from '../types/payments/payments';

export interface IUseProducts {
  pageInfo: IPaymentFlow;
}

interface IUseProductsData {
  productsListWithDiscount: CurrentProduct[];
  productsListWithoutDiscount: CurrentProduct[];
  productsListWithDiscountNew: CurrentProduct[];
  productsListWithoutDiscountNew: CurrentProduct[];
}

export function useProducts({ pageInfo }: IUseProducts): IUseProductsData {
  const setIsPreselectedValue = (list: CurrentProduct[], elementIndex: number) => {
    if (list[elementIndex]) list[elementIndex].is_preselected = true;
  };

  const getProductsList = () => {
    const products = pageInfo.products;

    const averageProductValue = products.length / 2;
    const elementIndex = products.findIndex((item) => item.is_preselected);

    const productsListWithDiscount = products.filter((product, i) => i < averageProductValue);
    const productsListWithoutDiscount = products.filter((product, i) => i >= averageProductValue);

    const productsListWithDiscountNew = products.filter((product) => product.start_price > product.price);
    const productsListWithoutDiscountNew = products.filter((product) => product.price >= product.start_price);

    setIsPreselectedValue(productsListWithDiscount, elementIndex);
    setIsPreselectedValue(productsListWithoutDiscount, elementIndex);

    return {
      productsListWithDiscount,
      productsListWithoutDiscount,
      productsListWithDiscountNew,
      productsListWithoutDiscountNew,
    };
  };

  return getProductsList();
}
