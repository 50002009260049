class Base {
    public apiClient: any;

    constructor({ apiClient }:any) {
        if (!apiClient) throw new Error('[apiClient] required');

        this.apiClient = apiClient;
    }
}

export default Base;
