import React from 'react';

import Header from '../Header';
import StepHeader from '../StepHeader';
import useOnboardingFlowForProgress from 'hooks/useOnboardingFlowForProgress';
import useSetBackground from 'hooks/useSetBackgound';

import { useHeader } from '../Header/hooks/useHeader';

interface IRootHeaderProps {
  onSkip: () => void;
}

const RootHeader = ({ onSkip }: IRootHeaderProps) => {
  const { isABNewHeader } = useOnboardingFlowForProgress();
  const props = useHeader();
  useSetBackground();

  if (!isABNewHeader) {
    return (
      <Header {...props} onSkip={onSkip} />
    );
  }

  return (
    <StepHeader {...props} onSkip={onSkip} />
  );
};


export default RootHeader;
