import {
  APPLE_PAY_LITERAL,
  CREDIT_CARD_LITERAL,
  DAYS_PER_EIGHT_MONTH,
  DAYS_PER_FIVE_MONTH,
  DAYS_PER_FOUR_MONTH,
  DAYS_PER_MONTH,
  DAYS_PER_ONE_YEAR,
  DAYS_PER_SEVEN_MONTH,
  DAYS_PER_SIX_MONTH,
  DAYS_PER_THREE_MONTH,
  DAYS_PER_TWO_MONTH,
  DAYS_PER_WEEK,
  GOOGLE_PAY_LITERAL,
  PAYMENT_TYPES,
  PAYMENT_TYPES_NAME,
  PAYMENT_TYPES_NAME_ID,
} from 'constants/payments';

import { PaymentMethod } from '../types/payments/payments';

export const ICUPeriodNormalizer = (period: number): { count: number; context: string; duration?: string } => {
  let normalizedPeriod = { count: 1, context: 'period', duration: 'duration' };

  switch (period) {
    case DAYS_PER_WEEK:
      normalizedPeriod = { count: 1, context: 'week', duration: 'weekly' };
      break;
    case DAYS_PER_MONTH:
      normalizedPeriod = { count: 1, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_TWO_MONTH:
      normalizedPeriod = { count: 2, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_THREE_MONTH:
      normalizedPeriod = { count: 3, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_FOUR_MONTH:
      normalizedPeriod = { count: 4, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_FIVE_MONTH:
      normalizedPeriod = { count: 5, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_SIX_MONTH:
      normalizedPeriod = { count: 6, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_SEVEN_MONTH:
      normalizedPeriod = { count: 7, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_EIGHT_MONTH:
      normalizedPeriod = { count: 8, context: 'month', duration: 'monthly' };
      break;
    case DAYS_PER_ONE_YEAR:
      normalizedPeriod = { count: 1, context: 'year', duration: 'annually' };
      break;
  }

  return normalizedPeriod;
};

export const isLifetime = (paymentType: string) => paymentType === 'lifetime';
export const isSubscription = (paymentType: string) => paymentType === 'subscription';

export const getPaymentIdByMethod = (paymentMethod: PaymentMethod) => {
  try {
    return PAYMENT_TYPES[paymentMethod];
  } catch (e) {
    console.error(e);
    throw Error(`no payment method founded in PAYMENT_TYPES with name ${paymentMethod}`);
  }
};

export const getPaymentTypesName = (activePayment: string) => {
  switch (activePayment) {
    case CREDIT_CARD_LITERAL:
      return PAYMENT_TYPES_NAME.CREDIT_CARD;
    case APPLE_PAY_LITERAL:
      return PAYMENT_TYPES_NAME.APPLE_PAY;
    case GOOGLE_PAY_LITERAL:
      return PAYMENT_TYPES_NAME.GOOGLE_PAY;
    default:
      return '';
  }
};

export const getPaymentTypesNameId = (activePayment: string) => {
  switch (activePayment) {
    case CREDIT_CARD_LITERAL:
      return PAYMENT_TYPES_NAME_ID.CREDIT_CARD;
    case APPLE_PAY_LITERAL:
      return PAYMENT_TYPES_NAME_ID.APPLE_PAY;
    case GOOGLE_PAY_LITERAL:
      return PAYMENT_TYPES_NAME_ID.GOOGLE_PAY;
    default:
      return '';
  }
};
