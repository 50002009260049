export const DEFAULT_FLOW_PROJECT_NAME = 'femia';
export const DEFAULT_FLOW = 'DEFAULT_FLOW';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;

export const DEFAULT_FLOW_TC = {
  branch_name: 'default',
  flow_name: 'femia__default__default__client',
  start_page: {
    id: 'ob_intro_tc',
    name: 'ob_intro_tc',
    config: {},
  },
  onboarding_flow: [
    {
      id: 'ob_birth_year',
      name: 'ob_birth_year',
      config: {},
    },
    {
      id: 'ob_cycle_regularity',
      name: 'ob_cycle_regularity',
      config: {},
    },
    {
      id: 'ob_mood_swings',
      name: 'ob_mood_swings',
      config: {},
    },
    {
      id: 'ob_experienced_symptoms',
      name: 'ob_experienced_symptoms',
      config: {},
    },
    {
      id: 'ob_mental_health',
      name: 'ob_mental_health',
      config: {},
    },
    {
      id: 'ob_sex_issues',
      name: 'ob_sex_issues',
      config: {},
    },
    {
      id: 'ob_birth_control',
      name: 'ob_birth_control',
      config: {},
    },
    {
      id: 'ob_health_problems',
      name: 'ob_health_problems',
      config: {},
    },
    {
      id: 'ob_period_duration',
      name: 'ob_period_duration',
      config: {},
    },
    {
      id: 'ob_cycle_duration',
      name: 'ob_cycle_duration',
      config: {},
    },
    {
      id: 'ob_plan_creation_steps',
      name: 'ob_plan_creation_steps',
      config: {},
    },
    {
      id: 'ob_enter_email',
      name: 'ob_enter_email',
      config: {},
    },
  ],
  payment_flow: [
    {
      id: 'payment',
      name: 'payment_fl2_7d',
      parent_id: null,
      is_paid: null,
      skip_checkout: false,
      products: [
        {
          payment_type: 'subscription',
          id: '74615bb9-2181-4fc9-8585-65de9e607b03',
          name: 'One-week intro (1.00) + One-month (24.99)',
          trial: 7,
          period: 30,
          product_code: 'app_full_access',
          is_preselected: true,
          product_state: 'per_month',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 100,
          start_price: 100,
          price: 100,
          sign: '$',
          code: 'USD',
          ltv: 2000,
        },
        {
          payment_type: 'subscription',
          id: 'a58e5872-734d-416a-9d62-fda347497bed',
          name: 'One-month intro (24.99) + One-month (24.99)',
          trial: 30,
          period: 30,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_month',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 2499,
          price_per_state: 2499,
          start_price: 2499,
          price: 2499,
          sign: '$',
          code: 'USD',
          ltv: 4403,
        },
      ],
      config: {},
    },
  ],
  expire: 1633705410444,
};

export const DEFAULT_FLOW_GP = {
  branch_name: 'default',
  flow_name: 'femia_gp__default__default__client',
  start_page: {
    id: 'ob_social_proof',
    name: 'ob_social_proof',
    config: {},
  },
  onboarding_flow: [
    {
      id: 'ob_gp_explanation',
      name: 'ob_gp_explanation',
      config: {},
    },
    {
      id: 'ob_feelings',
      name: 'ob_feelings',
      config: {},
    },
    {
      id: 'ob_first_pregnancy',
      name: 'ob_first_pregnancy',
      config: {},
    },
    {
      id: 'ob_birth_year',
      name: 'ob_birth_year',
      config: {},
    },
    {
      id: 'ob_when_to_conceive',
      name: 'ob_when_to_conceive',
      config: {},
    },
    {
      id: 'ob_conception_length',
      name: 'ob_conception_length',
      config: {},
    },
    {
      id: 'ob_children_number_v2',
      name: 'ob_children_number_v2',
      config: {},
    },
    {
      id: 'ob_social_proof_flow',
      name: 'ob_social_proof_flow',
      config: {},
    },
    {
      id: 'ob_ovulation_calculation',
      name: 'ob_ovulation_calculation',
      config: {},
    },
    {
      id: 'ob_best_graph',
      name: 'ob_best_graph',
      config: {},
    },
    {
      id: 'ob_examining',
      name: 'ob_examining',
      config: {},
    },
    {
      id: 'ob_cycle_regularity',
      name: 'ob_cycle_regularity',
      config: {},
    },
    {
      id: 'ob_period_duration',
      name: 'ob_period_duration',
      config: {},
    },
    {
      id: 'ob_cycle_duration',
      name: 'ob_cycle_duration',
      config: {},
    },
    {
      id: 'ob_cramps',
      name: 'ob_cramps',
      config: {},
    },
    {
      id: 'ob_first_log',
      name: 'ob_first_log',
      config: {},
    },
    {
      id: 'ob_did_you_know',
      name: 'ob_did_you_know',
      config: {},
    },
    {
      id: 'ob_body_signals',
      name: 'ob_body_signals',
      config: {},
    },
    {
      id: 'ob_pregnancy_support',
      name: 'ob_pregnancy_support',
      config: {},
    },
    {
      id: 'ob_reproductive_disorders',
      name: 'ob_reproductive_disorders',
      config: {},
    },
    {
      id: 'ob_symptoms_actual',
      name: 'ob_symptoms_actual',
      config: {},
    },
    {
      id: 'ob_fertility_target',
      name: 'ob_fertility_target',
      config: {},
    },
    {
      id: 'ob_health_check',
      name: 'ob_health_check',
      config: {},
    },
    {
      id: 'ob_height',
      name: 'ob_height',
      config: {},
    },
    {
      id: 'ob_weight',
      name: 'ob_weight',
      config: {},
    },
    {
      id: 'ob_health_problems',
      name: 'ob_health_problems',
      config: {},
    },
    {
      id: 'ob_prenatal_supplements',
      name: 'ob_prenatal_supplements',
      config: {},
    },
    {
      id: 'ob_sex_issues',
      name: 'ob_sex_issues',
      config: {},
    },
    {
      id: 'ob_doctor_comment',
      name: 'ob_doctor_comment',
      config: {},
    },
    {
      id: 'ob_diet',
      name: 'ob_diet',
      config: {},
    },
    {
      id: 'ob_business',
      name: 'ob_business',
      config: {},
    },
    {
      id: 'ob_job_description',
      name: 'ob_job_description',
      config: {},
    },
    {
      id: 'ob_back_pain',
      name: 'ob_back_pain',
      config: {},
    },
    {
      id: 'ob_sleep',
      name: 'ob_sleep',
      config: {},
    },
    {
      id: 'ob_confiding',
      name: 'ob_confiding',
      config: {},
    },
    {
      id: 'ob_plan_creation_bp',
      name: 'ob_plan_creation_bp',
      config: {},
    },
    {
      id: 'ob_enter_email',
      name: 'ob_enter_email',
      config: {},
    },
    {
      id: 'ob_before_after',
      name: 'ob_before_after',
      config: {},
    },
    {
      id: 'ob_boost_chart',
      name: 'ob_boost_chart',
      config: {},
    },
    {
      id: 'ob_welcome',
      name: 'ob_welcome',
      config: {},
    },
  ],
  payment_flow: [
    {
      id: 'payment_intro_discount',
      name: 'payment_intro_discount',
      parent_id: 'payment_intro',
      is_paid: false,
      skip_checkout: false,
      products: [
        {
          payment_type: 'subscription',
          id: 'ab90f9ca-d365-4468-887a-51b01201e78e',
          name: 'One-week intro (5.50) + One-month (30.99)',
          trial: 7,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 79,
          start_price: 3099,
          price: 550,
          sign: '$',
          code: 'USD',
          ltv: 2508,
        },
        {
          payment_type: 'subscription',
          id: '3af9e081-b45d-401e-b858-c6a68e153467',
          name: 'One-month intro (11.99) + One-month (30.99)',
          trial: 31,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 39,
          start_price: 3099,
          price: 1199,
          sign: '$',
          code: 'USD',
          ltv: 2769,
        },
        {
          payment_type: 'subscription',
          id: 'eb667562-a647-4ee7-bd72-b591148dd0d9',
          name: 'Three-month intro (21.49) + Three-month (53.19)',
          trial: 90,
          period: 90,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 59,
          price_per_state: 24,
          start_price: 5319,
          price: 2149,
          sign: '$',
          code: 'USD',
          ltv: 2905,
        },
        {
          payment_type: 'subscription',
          id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
          name: 'One-week intro (10.49) + One-month (30.99)',
          trial: 7,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 150,
          start_price: 3099,
          price: 1049,
          sign: '$',
          code: 'USD',
          ltv: 3045,
        },
        {
          payment_type: 'subscription',
          id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
          name: 'One-month intro (30.99) + One-month (30.99)',
          trial: 31,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 100,
          start_price: 3099,
          price: 3099,
          sign: '$',
          code: 'USD',
          ltv: 4778,
        },
        {
          payment_type: 'subscription',
          id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
          name: 'Three-month intro (53.19) + Three-month (53.19) ',
          trial: 90,
          period: 90,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 59,
          price_per_state: 59,
          start_price: 5319,
          price: 5319,
          sign: '$',
          code: 'USD',
          ltv: 5467,
        },
      ],
      config: {},
    },
    {
      id: 'payment_intro',
      name: 'payment_intro',
      parent_id: null,
      is_paid: null,
      skip_checkout: false,
      products: [
        {
          payment_type: 'subscription',
          id: 'c714c542-4373-41c9-b247-209815223772',
          name: 'One-week intro (6.93) + One-month (30.99)',
          trial: 7,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 99,
          start_price: 3099,
          price: 693,
          sign: '$',
          code: 'USD',
          ltv: 2560,
        },
        {
          payment_type: 'subscription',
          id: '9ea6bce5-34fe-4ee3-93d9-4ea5f455bc37',
          name: 'One-month intro (15.19) + One-month (30.99)',
          trial: 31,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: true,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 49,
          start_price: 3099,
          price: 1519,
          sign: '$',
          code: 'USD',
          ltv: 3936,
        },
        {
          payment_type: 'subscription',
          id: '7ad6132e-71fc-47c9-9e82-dcea25af4ac3',
          name: 'Three-month intro (25.99) + Three-month (53.19)',
          trial: 90,
          period: 90,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 59,
          price_per_state: 29,
          start_price: 5319,
          price: 2599,
          sign: '$',
          code: 'USD',
          ltv: 3587,
        },
        {
          payment_type: 'subscription',
          id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
          name: 'One-week intro (10.49) + One-month (30.99)',
          trial: 7,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 150,
          start_price: 3099,
          price: 1049,
          sign: '$',
          code: 'USD',
          ltv: 3045,
        },
        {
          payment_type: 'subscription',
          id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
          name: 'One-month intro (30.99) + One-month (30.99)',
          trial: 31,
          period: 31,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 100,
          price_per_state: 100,
          start_price: 3099,
          price: 3099,
          sign: '$',
          code: 'USD',
          ltv: 4778,
        },
        {
          payment_type: 'subscription',
          id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
          name: 'Three-month intro (53.19) + Three-month (53.19) ',
          trial: 90,
          period: 90,
          product_code: 'app_full_access',
          is_preselected: false,
          product_state: 'per_day',
          processor: 'solid',
          location: 'UA',
          start_price_per_state: 59,
          price_per_state: 59,
          start_price: 5319,
          price: 5319,
          sign: '$',
          code: 'USD',
          ltv: 5467,
        },
      ],
      config: {},
    },
  ],
  expire: 1633705410444,
};
