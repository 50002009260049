import React from 'react';
import { animated, useSpring, useSprings } from '@react-spring/web';

import cx from 'classnames';

import styles from './MarkedProgressBar.module.css';

const Marker = animated(({ ...rest }: React.HTMLAttributes<HTMLDivElement> & { index: number }) => {
  return (
    <div className={styles.markerContainer}>
      <div className={styles.markerContainerItem} />
      <animated.div
        className={styles.markerAnimated}
        {...rest}
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'>
          <path
            d='M2.3402 5.5C2.56121 5.5 2.73348 5.42697 2.85049 5.27247L5.87974 1.26966C5.96425 1.1573 6 1.05337 6 0.955056C6 0.691011 5.77248 0.5 5.46046 0.5C5.24594 0.5 5.11268 0.567416 4.98267 0.744382L2.32394 4.36798L0.975081 2.92135C0.854821 2.79775 0.72481 2.74157 0.542795 2.74157C0.227519 2.74157 0 2.93539 0 3.19944C0 3.31742 0.0422535 3.42135 0.159263 3.53652L1.8364 5.29213C1.97616 5.43539 2.13218 5.5 2.3402 5.5Z'
            fill='#FCFCFC' />
        </svg>
        {/* <IconCheck width={8} height={8} color='base-background' />*/}
      </animated.div>
    </div>
  );
});

interface MarkedProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  progress: number;
  dots: number;
  step: number;
}

let prevWidth = 0;
let prevStep = 0;

const MarkedProgressBar = ({ progress, dots, step, className, ...rest }: MarkedProgressBarProps) => {
  const progressStyle = useSpring({
    from: { width: `${prevWidth}%` },
    to: { width: `${progress * 100}%` },
    config: { duration: 500 },
  });

  React.useEffect(() => {
    prevWidth = progress * 100;
  }, [progress]);

  const [segments] = useSprings(
    dots,
    (i) => {
      if (i === step && step > prevStep) {
        return {
          from: { opacity: 0 },
          to: { opacity: 1 },
          config: { duration: 500 },
        };
      }

      return {
        opacity: i <= step ? 1 : 0,
      };
    },
    [step],
  );

  React.useEffect(() => {
    prevStep = step;
  }, [step]);

  return (
    <div className={cx(styles.barContainer, className)} {...rest}>
      <animated.div className={styles.bar} style={progressStyle} />

      {segments.map((style, index) => {
        if (index === 0) return /* <Marker key={index} index={index}  /> */;

        return (
          <div key={index} className={styles.barProgressItem} style={{ width: `calc(100% / ${segments.length - 1})` }}>
            <div className={styles.barProgress}>
              <div className={styles.barEl} />

              <animated.div className={styles.barEl1} style={style} />
            </div>

            <Marker index={index} style={style} />
          </div>
        );
      })}
    </div>
  );
};

export default MarkedProgressBar;
