import * as actionTypes from './actionTypes';

import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments';

import { InferValueTypes } from 'types/commonInterfaces';
import * as action from './actions';

const initialState: any = {
  currentProduct: null,
  previousProduct: null,
  currency: PAYMENT_DEFAULT_CURRENCY,
  checkoutOrderId: null,
  isCustomPaymentButton: false,
  isPaymentScreen: false,
  isApplePayAvailable: false,
  isGooglePayAvailable: false,
  isShowApplePayMethod: false,
  isShowGooglePayMethod: false,
  paymentConfig: {
    paypal: true,
    applePay: false,
    sandbox: false,
  },
  allProductsFromFirstPayment: [],
  paymentDiscount: 0,
  currentProductCode: null,
  oneClickPaymentPrice: null,
  upsellId: null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const PaymentReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: action.payload,
      };

    case actionTypes.SET_PAYMENT_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    case actionTypes.CHANGE_PAYMENT_TYPE:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case actionTypes.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.payload,
      };

    case actionTypes.VALIDATE_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SET_EXCLUSIVE_OFFER:
      return {
        ...state,
        exclusiveOfferStatus: action.payload,
      };

    case actionTypes.SET_HIDE_CUSTOM_PAYMENT_BUTTON:
      return {
        ...state,
        isHideCustomPaymentButton: action.payload,
      };

    case actionTypes.SET_MERCHANT_DATA:
      return {
        ...state,
        merchantData: action.payload,
      };

    case actionTypes.SET_PAYMENT_DATA:
      return {
        ...state,
        initPaymentData: action.payload,
      };

    case actionTypes.SET_ORDER_ID:
      return {
        ...state,
        checkoutOrderId: action.payload,
      };

    case actionTypes.SET_BANKCARD_SRC:
      return {
        ...state,
        bankCardSrc: action.payload,
      };

    case actionTypes.SET_PAYMENT_CONFIG: {
      const newPaymentConfig = Object.assign({}, state.paymentConfig, action.payload);

      return {
        ...state,
        paymentConfig: newPaymentConfig,
      };
    }

    case actionTypes.SET_SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.payload,
      };

    case actionTypes.VALIDATE_PAYMENT_DATA:
      return {
        ...state,
        validatePaymentData: action.payload,
      };

    case actionTypes.VALIDATE_PAYMENT_FAIL: {
      return {
        ...state,
        isLoading: false,
        paymentValidate: {
          isValid: false,
          message: action.payload,
        },
      };
    }

    case actionTypes.SET_CUSTOM_PAYMENT_BUTTON:
      return { ...state, isCustomPaymentButton: action.payload };

    case actionTypes.SET_PAYMENT_TYPE:
      // @ts-ignore
      localStorage.setItem('paymentType', action.payload);

      return { ...state, paymentType: action.payload };

    case actionTypes.SET_IS_PAYMENT_SCREEN:
      return { ...state, isPaymentScreen: action.payload };

    case actionTypes.INIT_SOLID_PAYMENT:
      return { ...state, solidInitiated: action.payload };

    case actionTypes.SET_IS_APPLE_PAY_AVAILABLE:
      return { ...state, isApplePayAvailable: action.payload };

    case actionTypes.SET_SHOW_APPLE_PAY_METHOD:
      return { ...state, isShowApplePayMethod: action.payload };

    case actionTypes.SET_IS_GOOGLE_PAY_AVAILABLE:
      return { ...state, isGooglePayAvailable: action.payload };

    case actionTypes.SET_SHOW_GOOGLE_PAY_METHOD:
      return { ...state, isShowGooglePayMethod: action.payload };

    case actionTypes.SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT:
      return { ...state, allProductsFromFirstPayment: action.payload };

    case actionTypes.SET_PAYMENT_DISCOUNT:
      return { ...state, paymentDiscount: action.payload };

    case actionTypes.SET_CURRENT_PRODUCT_CODE:
      return { ...state, currentProductCode: action.payload };

    case actionTypes.SET_UPSELL_PRODUCT_PRICE:
      return {
        ...state,
        oneClickPaymentPrice: action.payload,
      };

    case actionTypes.SET_UPSELL_PRODUCT_ID:
      return {
        ...state,
        upsellId: action.payload,
      };

    case actionTypes.SET_PREVIOUS_PRODUCT:
      return {
        ...state,
        previousProduct: action.payload,
      };
    default:
      return state;
  }
};

export default PaymentReducer;
