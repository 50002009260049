import { useEffect } from 'react';
import { AnalyticUtils, Main } from 'wikr-core-analytics';

import { replaceToUnderscore } from 'helpers/utilsUpdated';

export interface ISendAnalyticsWithEventLabel {
  url?: string;
  eventValue?: any;
}

export interface IUseAnalyticsData {
  analytic: typeof Main;
  track: (event: string) => void;
  trackClick: (event: string) => void;
  purchase: (data: any) => void;
  customData: (eventName: string, data?: any, allSystem?: boolean) => void;
  standardEvent: (eventName: string, data: any, allSystem?: boolean) => void;
  sendAnalyticsWithEventLabel: ({ url, eventValue }: ISendAnalyticsWithEventLabel) => void;
  getUrl: () => string;
}

export function useAnalytics(trackFlag = false): IUseAnalyticsData {
  const { location } = window;

  const analytic = Main;

  const getUrl = () => {
    return location.pathname.replace(/(\/([a-z]{2})\/)|\//g, '');
  };

  useEffect(() => {
    const url = getUrl();

    if (!trackFlag) {
      track(url);
    }
  }, []);

  const track = (event: string) => {
    analytic.track(event, { selectedSystems: ['amazon', 'pinterest'], isPrefix: true });
  };

  const trackClick = (event: string) => {
    analytic.trackClick(event);
  };

  const purchase = (data: any) => {
    analytic.purchase(data);
  };

  const customData = (eventName: string, data: any = {}, allSystem = false) => {
    // only for pageVisit event to Pinterest
    const isLoadEvent = eventName.includes('__screen__load');
    const customSystems = isLoadEvent ? ['amazon', 'pinterest'] : ['amazon'];

    const options = {
      event: eventName,
      data: AnalyticUtils.normalizeEventLabelFormat(data),
      ...(!allSystem && { customSystems }),
    };

    analytic.sendAll(options, (system: any) => {
      system.track(AnalyticUtils.normalizeLabel(eventName), data);
    });
  };

  const standardEvent = (eventName: string, data: any = null, allSystem = false) => {
    const options = {
      event: eventName,
      data: AnalyticUtils.normalizeEventLabelFormat(data),
      ...(!allSystem && { customSystems: ['amazon'] }),
    };

    analytic.sendAll(options, (system: any) => {
      system.track(eventName, data);
    });
  };

  const sendAnalyticsWithEventLabel = ({
                                         url = window.location.pathname,
                                         eventValue: data,
                                       }: ISendAnalyticsWithEventLabel) => {
    const formattedUrl = url.replace(/(\/([a-z]{2})\/)|\//g, '');

    const eventValue = data;

    const eventName = `${replaceToUnderscore(formattedUrl)}__any__click`;

    customData(eventName, { event_label: eventValue });
  };

  return {
    analytic,
    track,
    trackClick,
    purchase,
    customData,
    standardEvent,
    sendAnalyticsWithEventLabel,
    getUrl,
  };
}
