// helpers
import { setOptions } from './helpers/utils';

// constants
import { REQUEST_HEADERS } from './constants';

// services
import StrategyManager from './services/strategyManager';

// interfaces
import { InitSolidPayment, OneClickPayment } from './interfaces/payment';

// types
import { Init } from './types/payment';
import { IOnErrorData } from './api/types/api';

// api
import api from './apiSingleton';

class Solid {
    private strategyManager: StrategyManager;

    constructor() {
        this.strategyManager = new StrategyManager();
    }

    public async setHeaders(props: InitSolidPayment) {
        try {
            await setOptions(props, REQUEST_HEADERS);
        } catch (e) {
            console.error(e);
        }
    }

    public async init(payment: string, payload: Init) {
        try {
            const strategy = await this.strategyManager.getStrategy(payment);

            return await strategy.initPayment(payload);
        } catch (e) {
            console.error(e);

            return e;
        }
    }

    public async validate(payment: string, payload: any) {
        try {
            const strategy = await this.strategyManager.getStrategy(payment);

            return await strategy.validatePayment(payload);
        } catch (e) {
            console.error(e);

            return e;
        }
    }

    public async replaceSubscription(payment: string, payload: any) {
        try {
            const strategy = await this.strategyManager.getStrategy(payment);

            return strategy.replaceSubscription(payload);
        } catch (e) {
            console.error(e);

            return e;
        }
    }

    public async oneClickPayment(payment: string, payload: OneClickPayment) {
        try {
            const strategy = await this.strategyManager.getStrategy(payment);

            return strategy.oneClickPayment(payload);
        } catch (e) {
            console.error(e);

            return e;
        }
    }

    public onError(callback: ({ error, tmpOptions }: IOnErrorData) => void) {
        api.onError(callback);
    }
}

export default new Solid();
