import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'services/i18n';
import Sentry from './services/Sentry';
import App from './App';
import configureStore from './configureStore';

import './styles.css';
const { store, persistor } = configureStore();

Sentry.init();

const container = document.getElementById('app') as HTMLDivElement;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        {/* @ts-ignore*/}
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);
