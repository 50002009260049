import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// helpers
import { setAnalyticData, setUserPlatform } from './helpers';
// services
import { initAmazonAnalytic } from 'services/Analytics';
import { initHotjar } from 'services/Analytics/init';

import { setAppLoader } from 'redux/UiEffects/actions';
import { selectUser } from 'redux/User/selectors';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { selectUrlParams } from 'redux/Onboadring/selectors';
import { initAutoLogin } from 'redux/Onboadring/actions';
import { setIsAutologinComplete } from 'redux/User/actions';

import { getUrlParams } from 'helpers/utils';
import { setFavicon } from 'helpers/setFavicon';

function useAppInit() {
  const dispatch = useDispatch();

  const prevCountry = useRef<string | null>(null);
  const prevAbTestName = useRef<string | null>(null);
  const prevUserId = useRef<string | number | null>(null);

  // @ts-ignore
  const { country, user_id: userId } = useSelector(selectUser);
  const abTestName = useSelector(selectTestaniaName);
  const urlParams = useSelector(selectUrlParams);

  const isResetStore = localStorage.getItem('isResetStore') || null;
  const branchName = localStorage.getItem('branch-name');

  setFavicon(branchName);

  if (isResetStore) {
    dispatch({ type: 'RESET_STATE' });
  }

  const paramsInUrl = getUrlParams();

  const setNewPropsToRef = () => {
    prevCountry.current = country;
    prevAbTestName.current = abTestName;
    // @ts-ignore
    prevUserId.current = userId;
  };

  useEffect(() => {
    const testaniaStatus = localStorage.getItem('testaniaResponseStatus');

    setNewPropsToRef();
    setAnalyticData({ country, abTestName, urlParams, userId });
    setUserPlatform(dispatch);

    initAmazonAnalytic();
    // initAnalytics(branchName);
    // initGoogleAnalytic(branchName);

    if (paramsInUrl?.token) {
      dispatch(initAutoLogin());
    } else {
      dispatch(setIsAutologinComplete(true));
    }

    if (testaniaStatus) {
      dispatch(setAppLoader(false));
    }

    initHotjar();
  }, []);

  useEffect(() => {
    if (prevCountry.current !== country || prevAbTestName.current !== abTestName || prevUserId.current !== userId) {
      setAnalyticData({ country, abTestName, urlParams, userId });
      setNewPropsToRef();
    }
  }, [country, abTestName, urlParams, userId]);
}

export default useAppInit;
