import AbstractPayment from '../abstractPayment';
import {PAY_PAL} from '../../constants';

class PayPal extends AbstractPayment {
    private buttonWrapperDiv: HTMLElement | null | undefined;
    private isHandlerActive: boolean;

    constructor() {
        super(PAY_PAL);
        this.isHandlerActive = false;
        this.buttonWrapperDiv = null;
    }

    async initPayment(payload: any): Promise<any> {
        const responseInit: any = await super.initPayment(payload);

        if (!payload.recurring) {
            const url = responseInit.pay_form.script_url;

            this.insertScript(url);

            if (!this.isHandlerActive || this.buttonWrapperDiv?.getAttribute('listener') !== 'true') {
                this.activeHandlers(payload);
            }
        }
        
        return responseInit;
    }

    activeHandlers(payload: any) {
        this.onClick(payload.onClickHandler);
        this.buttonErrorHandler(payload.errorHandler);
        this.buttonReadyHandler(payload.readyHandler);
        this.orderProcessedHandler(payload.successHandler);

        this.isHandlerActive = true;
        this.buttonWrapperDiv?.setAttribute('listener', 'true');
    }

    insertScript(url: string) {
        const buttonWrapper = document.getElementById('paypal-button');

        if (!url || !buttonWrapper) throw Error('url && buttonWrapper required for init PayPal button');

        const { head } = document;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;

        script.setAttribute('data-script', ' ');
        script.setAttribute('data-btn-id', 'solid_pay_btn');
        script.setAttribute('data-overlay', 'true');
        script.setAttribute('data-tittle', 'Tittle for payment widget');
        script.setAttribute('data-description', 'widget.data-description');

        head.insertBefore(script, head.firstChild);

        this.buttonWrapperDiv = buttonWrapper;
    }

    buttonErrorHandler(callback: any) {
        this.buttonWrapperDiv?.addEventListener(
            'button-error',
            (e: any) => {
                console.error('button-error', e);
                callback(e);
            },
            false
        );
    }
    buttonReadyHandler(callback = () => {}) {
        this.buttonWrapperDiv?.addEventListener(
            'button-ready',
            (e: any) => {
                console.log('button-ready', e);
                callback();
            },
            false
        );
    }

    orderProcessedHandler(callback: any) {
        this.buttonWrapperDiv?.addEventListener(
            'order-processed',
            (e: any) => {
                callback(e.detail.data); // return order, pay_info
            },
            false
        );
    }

    onClick(callback = () => {}) {
        this.buttonWrapperDiv?.addEventListener(
            'order-started-processing',
            () => {
                callback();
            },
            false
        );
    }

}

export default PayPal;
