import { Main } from 'wikr-core-analytics';

// services
import sentry from 'services/Sentry/Sentry';

// constants
import { COOKIE_GROUP_LIST, TARGETING_COOKIE } from 'constants/analytics';
import { ERROR_LEVELS, SENTRY_ONE_TRUST } from 'constants/sentry';

// helpers
import { getAllAnalyticInitList, getAnalyticInitList } from './helper';
import { initGoogleAnalytic } from '../Analytics';

const stringToArray = (str) => (str ? str.slice(1, -1).split(',') : []);

const isAcceptedAllCookies = (cookieList) => COOKIE_GROUP_LIST.every((groupId) => cookieList.includes(groupId));

export default class OneTrust {
  #instance;

  constructor(OneTrust) {
    this.#instance = OneTrust;
    this.isAllowedAll =
      OneTrust?.IsAlertBoxClosed() && isAcceptedAllCookies(stringToArray(window?.OptanonActiveGroups));
    this.isBannerClosed = OneTrust?.IsAlertBoxClosed();
    this.acceptedCookieList = OneTrust?.IsAlertBoxClosed() ? stringToArray(window?.OptanonActiveGroups) : [];
  }

  setError(methodName) {
    sentry.logError(new Error(`OneTrust method ${methodName} don't work`), SENTRY_ONE_TRUST, ERROR_LEVELS.CRITICAL);
  }

  consentHandler(oneTrustEvent) {
    const { detail } = oneTrustEvent;

    if (!this.isBannerClosed) {
      this.acceptedCookieList = detail;
    }

    const cookieList = this.isBannerClosed ? this.acceptedCookieList : detail;

    this.isAllowedAll = isAcceptedAllCookies(cookieList);

    const branchName = localStorage.getItem('branch-name');

    const activeSystems = getAnalyticInitList(cookieList, branchName);

    if (this.isAllowedAll && !this.isBannerClosed) {
      if (activeSystems.length) {
        Main.initSystems({ activeSystems });
        initGoogleAnalytic(branchName);

        this.isBannerClosed = true;
      }
    } else if (activeSystems.length && this.isBannerClosed) {

      Main.initSystems({ activeSystems });

      if (cookieList.includes(TARGETING_COOKIE)) {
        initGoogleAnalytic(branchName);
      }
    }
  }

  init() {
    if (this.isBannerClosed) {
      this.consentHandler({ detail: this.acceptedCookieList });
    } else {
      this.#instance?.OnConsentChanged
        ? this.#instance.OnConsentChanged(this.consentHandler)
        : this.setError('OnConsentChanged');
    }
  }

  rejectAll() {
    this.#instance?.RejectAll ? this.#instance?.RejectAll() : this.setError('rejectAll');
  }

  allowAll() {
    const branchName = localStorage.getItem('branch-name');

    if (!this.isAllowedAll) {
      const activeSystems = getAllAnalyticInitList(branchName);

      Main.initSystems({ activeSystems });
      initGoogleAnalytic(branchName);
      this.isAllowedAll = true;
      this.#instance?.AllowAll ? this.#instance.AllowAll() : this.setError('AllowAll');
    }
  }
}
