import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';
import { PaymentStore } from 'interfaces/Store/paymentStore';

const selectPayment = (state: RootState): PaymentStore => state.payment;

const selectCurrency = createSelector(selectPayment, (payment) => payment.currency);
const selectCurrentProduct = createSelector(selectPayment, (payment) => payment.currentProduct);
const selectPaymentMethod = createSelector(selectPayment, (payment) => payment.paymentMethod);
const selectIsDisplayCustomPaymentButton = createSelector(
  selectPayment,
  ({ isCustomPaymentButton, isHideCustomPaymentButton }) => isCustomPaymentButton || isHideCustomPaymentButton,
);
const selectSolidInitiated = createSelector(selectPayment, (payment) => payment.solidInitiated);
const selectPaymentConfigSandbox = createSelector(selectPayment, (payment) => payment.paymentConfig.sandbox);
const selectIsPaymentScreen = createSelector(selectPayment, (payment) => payment.isPaymentScreen);

const selectIsShowApplePayMethod = createSelector(selectPayment, (payment) => payment.isShowApplePayMethod);
const selectIsShowGooglePayMethod = createSelector(selectPayment, (payment) => payment.isShowGooglePayMethod);

const selectCheckoutOrderId = createSelector(selectPayment, (payment) => payment.checkoutOrderId);
const selectCurrentProductCode = createSelector(selectPayment, (payment) => payment.currentProductCode);
const selectOneClickPaymentPrice = createSelector(selectPayment, (payment) => payment.oneClickPaymentPrice);
const selectUpsellId = createSelector(selectPayment, (payment) => payment.upsellId);
const selectPreviousProduct = createSelector(selectPayment, (payment) => payment.previousProduct);

export {
  selectPayment,
  selectCurrency,
  selectCurrentProduct,
  selectSolidInitiated,
  selectIsDisplayCustomPaymentButton,
  selectPaymentConfigSandbox,
  selectPaymentMethod,
  selectIsPaymentScreen,
  selectIsShowApplePayMethod,
  selectCheckoutOrderId,
  selectCurrentProductCode,
  selectOneClickPaymentPrice,
  selectUpsellId,
  selectPreviousProduct,
  selectIsShowGooglePayMethod,
};
