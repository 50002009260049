import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import useAppInit from './hooks/useAppInit';
import useSkipOnboarding from './hooks/useSkipOnboarding';

import { OneTrustProvider } from 'services/OneTrustProvider';
import ErrorMessage from 'components/ErrorMessage';
import ReleaseConfig from 'components/ReleaseConfig';
import InitTestania from 'pages/GeneralPages/InitTestania';
import Routers from './router';
import StartAnalyticsContainer from 'containers/OtherContainers/StartAnalyticsContainer';
import RootHeader from './components/RootHeader';
import GuaranteeModal from 'modals/GuaranteeModal';

// @ts-ignore
export const Context = React.createContext();

const App = () => {
  const flowFromLS = localStorage.getItem('userGoal');
  const [flow, setFlow] = useState(flowFromLS);

  const { onSkip } = useSkipOnboarding();
  useAppInit();

  return (
    <OneTrustProvider>
      <Context.Provider value={[flow, setFlow]}>
        <BrowserRouter>
          <ErrorMessage />
          <GuaranteeModal />
          <RootHeader onSkip={onSkip} />
          <ReleaseConfig />
          <React.Suspense fallback={null}>
            {/* {flow && <InitTestania />}*/}
            <InitTestania />
            <Routers />
            <StartAnalyticsContainer />
          </React.Suspense>
        </BrowserRouter>
      </Context.Provider>
    </OneTrustProvider>
  );
};

export default App;
