export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
export const SET_PAYMENT_CURRENCY = 'SET_PAYMENT_CURRENCY';
export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const VALIDATE_PAYMENT = 'VALIDATE_PAYMENT';
export const INIT_PAY_PAL = 'INIT_PAY_PAL';
export const SET_EXCLUSIVE_OFFER = 'SET_EXCLUSIVE_OFFER';
export const SET_HIDE_CUSTOM_PAYMENT_BUTTON = 'SET_HIDE_CUSTOM_PAYMENT_BUTTON';
export const SET_PAYMENT_RETENO_ERROR = 'SET_PAYMENT_RETENO_ERROR';
export const INIT_PAYMENT_GENERATOR = 'INIT_PAYMENT_GENERATOR';
export const SET_MERCHANT_DATA = 'SET_MERCHANT_DATA';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_BANKCARD_SRC = 'SET_BANKCARD_SRC';
export const INIT_BANK_CARD = 'INIT_BANK_CARD';
export const INIT_SOLID_PAYMENT = 'INIT_SOLID_PAYMENT';
export const SET_PAYMENT_CONFIG = 'SET_PAYMENT_CONFIG';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const SET_SUBSCRIPTION_ID = 'SET_SUBSCRIPTION_ID';
export const VALIDATE_PAYMENT_DATA = 'VALIDATE_PAYMENT_DATA';
export const VALIDATE_PAYMENT_FAIL = 'VALIDATE_PAYMENT_FAIL';
export const SET_CUSTOM_PAYMENT_BUTTON = 'SET_CUSTOM_PAYMENT_BUTTON';
export const SET_IS_PAYMENT_SCREEN = 'SET_IS_PAYMENT_SCREEN';
export const SET_IS_APPLE_PAY_AVAILABLE = 'SET_IS_APPLE_PAY_AVAILABLE';
export const SET_SHOW_APPLE_PAY_METHOD = 'SET_SHOW_APPLE_PAY_METHOD';
export const SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT = 'SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT';
export const SET_PAYMENT_DISCOUNT = 'SET_PAYMENT_DISCOUNT';
export const SET_CURRENT_PRODUCT_CODE = 'SET_CURRENT_PRODUCT_CODE';
export const SET_UPSELL_PRODUCT_PRICE = 'SET_UPSELL_PRODUCT_PRICE';
export const SET_UPSELL_PRODUCT_ID = 'SET_UPSELL_PRODUCT_ID';
export const INIT_ONE_CLICK_PAYMENT = 'INIT_ONE_CLICK_PAYMENT';
export const SET_PREVIOUS_PRODUCT = 'SET_PREVIOUS_PRODUCT';
export const SET_SHOW_GOOGLE_PAY_METHOD = 'SET_SHOW_GOOGLE_PAY_METHOD';
export const SET_IS_GOOGLE_PAY_AVAILABLE = 'SET_IS_GOOGLE_PAY_AVAILABLE';
