import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMarkedHeader } from './hooks/useMarkedHeader';
import { useProgressValue } from './hooks/useProgressValue';
import useSectionedStepHeader from './hooks/useSectionedStepHeader';
import useOnboardingFlowForProgress from 'hooks/useOnboardingFlowForProgress';

import MarkedProgressBar from 'components/MarkedProgressBar';

import config from 'config';

import styles from './StepHeader.module.css';

import BackIcon from 'assets/img/Back.svg';
import Logo from 'assets/img/Logo.svg';

export interface IStepHeaderProps {
  restrictHeader?: boolean;
  isHideHeader?: boolean;
  onboardingHeader?: boolean;
  isShowBackButton?: boolean;
  isDisabledSmoothScroll?: boolean;
  handleBackButtonClick?: () => void;
  onSkip?: () => void;
}

const StepHeader = ({
                      restrictHeader,
                      isHideHeader,
                      onboardingHeader,
                      isShowBackButton,
                      isDisabledSmoothScroll,
                      handleBackButtonClick,
                      onSkip,
                    }: IStepHeaderProps) => {
  const { progressConfig } = useOnboardingFlowForProgress();
  const { t } = useTranslation();

  const progress = useProgressValue();
  const { title, titleColor } = useSectionedStepHeader();
  const { step, dots } = useMarkedHeader(progress, progressConfig.sections.length + 1);

  return (
    <>
      {(restrictHeader && !isHideHeader) && (
        <div className={classNames(styles.headerContainer, {
          [styles.disabledSmoothScroll]: isDisabledSmoothScroll,
        })}>
          <div className={styles.container}>
            {(onboardingHeader || isShowBackButton) && (
              <div className={styles.backArrow} onClick={handleBackButtonClick} role='presentation'>
                <BackIcon className={styles.iconBack} />
              </div>
            )}
            {!onboardingHeader ? (
              <Logo className={styles.logo} />
            ) : (
              <span className={styles.sectionStepTitle} style={{ color: titleColor }}>
                  {t(title?.defaultMessage as string)}
               </span>
            )}
            {/* @ts-ignore */}
            {config.ENV === 'stage' && onboardingHeader && (
              <div onClick={onSkip} className={styles.skip}>skip</div>
            )}
          </div>
          {onboardingHeader && (
            <MarkedProgressBar progress={progress} dots={dots} step={step} className='mt-4' />
          )}
        </div>
      )}
    </>
  );
};


export default StepHeader;
