export const REQUEST_HEADERS: any = {
  apiUrl: '',
  apiVersion: '7',
  apiKey: '',
  authorization: '',
  language: 'en',
  sandbox: false,
};

export const APPLE_PAY = 'applePay';
export const PAY_PAL = 'payPal';
export const BANK_CARD = 'bankCard';
export const PAYMENT_GENERATOR = 'paymentGenerator';
