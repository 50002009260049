import { OnboardingFlow } from 'testania';

const motherhoodConfig = {
  lastScreenId: 'ob_gn_pace',
  sections: [
    {
      startScreenId: 'ob_first_pregnancy',
      title: {
        defaultMessage: 'ob.stepHeader.motherhood.title1',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_height', // 'ob_block_eggs',
      title: {
        defaultMessage: 'ob.stepHeader.motherhood.title2',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_block_intimacy',
      title: {
        defaultMessage: 'ob.stepHeader.motherhood.title3',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_block_hormones',
      title: {
        defaultMessage: 'ob.stepHeader.motherhood.title4',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_block_psych',
      title: {
        defaultMessage: 'ob.stepHeader.motherhood.title5',
      },
      titleColor: '#3A62B2',
    },
  ],
};

const sexConfig2_0 = {
  lastScreenId: 'ob_body_lang',
  sections: [
    {
      startScreenId: 'ob_birth_year_sex',
      title: {
        defaultMessage: 'ob.stepHeader.sex2_0.title1',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_month_desire', // 'ob_improve_sex',
      title: {
        defaultMessage: 'ob.stepHeader.sex2_0.title2',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_sex_over_fun', // 'ob_sex_state',
      title: {
        defaultMessage: 'ob.stepHeader.sex2_0.title3',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_feel_loved', // 'ob_vibrant_sex',
      title: {
        defaultMessage: 'ob.stepHeader.sex2_0.title4',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_cycle_sex_new', // 'ob_couple_bond',
      title: {
        defaultMessage: 'ob.stepHeader.sex2_0.title5',
      },
      titleColor: '#3A62B2',
    },
  ],
};


const sexConfig3_0 = {
  lastScreenId: 'ob_plan_creation_steps_lv_new',
  sections: [
    {
      startScreenId: 'ob_birth_year_sex',
      title: {
        defaultMessage: 'ob.stepHeader.sex3_0.title1',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_block_sexmind',
      title: {
        defaultMessage: 'ob.stepHeader.sex3_0.title2',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_block_pleasure',
      title: {
        defaultMessage: 'ob.stepHeader.sex3_0.title3',
      },
      titleColor: '#3A62B2',
    },
    {
      startScreenId: 'ob_block_reward',
      title: {
        defaultMessage: 'ob.stepHeader.sex3_0.title4',
      },
      titleColor: '#3A62B2',
    },
  ],
};

export const getConfig = (branchName: string | null, onboardingFlow: OnboardingFlow[]) => {
  if (branchName === 'motherhood') {
    return motherhoodConfig;
  }

  if (branchName === 'lv') {
    const sexScreenIds3_0 = sexConfig3_0.sections.map((section) => section.startScreenId);
    const onboardingFlowIds = onboardingFlow.map((page) => page.id);

    const isSexFlow3_0 = sexScreenIds3_0.every(id => onboardingFlowIds.includes(id));

    return isSexFlow3_0 ? sexConfig3_0 : sexConfig2_0;
  }

  // In future we can add more configs for different branches
  return motherhoodConfig;
};

