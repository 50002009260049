import React, { ReactElement, ReactNode } from 'react';
import { Text } from 'libComponents';
import cx from 'classnames';

import styles from './ObCtaBtn.module.css';

interface ICtaBtnProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  isShadow?: boolean;
}

const ObCtaBtn = ({ label, onClick, disabled, children, className, isShadow = true }: ICtaBtnProps): ReactElement => (
  <div className={cx([styles.btnContainer, {
    [styles.double]: !!children,
    [styles.shadow]: isShadow,
  }])}>
    <div className={styles.position}>
      {children}
      <button className={cx([styles.btn, className])} onClick={onClick} disabled={disabled}>
        <Text className={styles.btnTitle}>{label}</Text>
      </button>
    </div>
  </div>
);

export default ObCtaBtn;
