import React, { ReactElement, useEffect, useState } from 'react';

import ObCtaBtn from 'components/ObCtaBtn';

import Option from './Option';

const OptionsBox = ({ optionsData, onClick, buttonData, multiple, withIcon, className }: any): ReactElement => {
  const [singleValue, setSingleValue] = useState('' as string);
  const [multipleValue, setMultipleValue] = useState([] as Array<string>);

  const multipleHandleOption = (value: string, checked: boolean) => {
    const resultArr = [...multipleValue];

    if (!checked) {
      const deleteIndex = resultArr.findIndex(element => element === value);
      resultArr.splice(deleteIndex, 1);
    } else {
      resultArr.push(value);
    }
    setMultipleValue(resultArr);
  };

  const singleHandleOption = (value: string) => {
    setSingleValue(value);
  };

  const handleOption = (value: string, checked: boolean) => {
    if (multiple) {
      multipleHandleOption(value, checked);
    } else if (checked) {
      singleHandleOption(value);
    }
  };

  // TODO: remove
  useEffect(() => {
    if (onClick && singleValue) {
      onClick(singleValue);
    }
  }, [singleValue]);

  return (
    <>
      <div className={className}>
        {optionsData?.map(({ title, subTitle, Icon, rightCheckbox, value, className }: any, index: any): ReactElement =>
          <Option className={className} withIcon={withIcon} key={index} title={title} subTitle={subTitle} Icon={Icon}
                  rightCheckbox={rightCheckbox} value={value}
                  onClick={handleOption} />,
        )}
      </div>
      {buttonData && multiple && (
        <ObCtaBtn label={buttonData.text} onClick={() => onClick && onClick(multipleValue)} />)}
    </>
  );
};

export default OptionsBox;
