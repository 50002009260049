import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectUser = (state: RootState) => state.user;

const selectCountry = createSelector(selectUser, (user) => user.country);
const selectIsLoading = createSelector(selectUser, (user) => user.isLoading);
const selectToken = createSelector(selectUser, (user) => user.token);
const selectUserId = createSelector(selectUser, (user) => user.user_id);
const selectEmailError = createSelector(selectUser, (user) => user.emailError);
const selectEmailSuccess = createSelector(selectUser, (user) => user.emailSuccess);
const selectIsAutologinComplete = createSelector(selectUser, (user) => user.isAutologinComplete);

export {
  selectUser,
  selectCountry,
  selectIsLoading,
  selectToken,
  selectUserId,
  selectEmailError,
  selectEmailSuccess,
  selectIsAutologinComplete,
};
