import Solid from 'services/SolidSDK';
import { takeLatest, put } from 'redux-saga/effects';

// actionTypes
import * as actionTypes from 'redux/Payment/actionTypes';
import { setMerchantData } from 'redux/Payment/actions';

// interfaces
import { ActionType } from 'types/commonInterfaces';
import { InitConfig } from '@solidgate/client-sdk-loader';

function* init({ payload }: ActionType<any>) {
    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

        const response: InitConfig['merchantData'] = yield Solid.init('paymentGenerator', payload);

        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
        yield put(setMerchantData(response));
    } catch (e) {
        console.error('Init payment error', e);
    }
}

export const initPaymentGenerator = [takeLatest(actionTypes.INIT_PAYMENT_GENERATOR, init)];
