export const setFavicon = (branchName: string | null) => {
  const favicon = branchName === 'lv' ? 'femia-sex.png' : 'femia.png';

  let link = document.querySelector('link[rel~=\'icon\']') as HTMLLinkElement;

  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.id = 'favicon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  link.href = `/favicons/${favicon}`;
};
