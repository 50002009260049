import AbstractPayment from '../abstractPayment';
import {InitBankCard} from '../../interfaces/payment';
import {BANK_CARD} from '../../constants';

// helpers
import { pastSolidScript } from '../../helpers/utils';

// api
import api from '../../apiSingleton';

class BankCard extends AbstractPayment {
    private isHandlerActive: boolean;
    private brandCard: string;

    constructor() {
        super(BANK_CARD);
        this.isHandlerActive = false;
        this.brandCard = '';
        pastSolidScript();
    }

    public async initPayment(payload: InitBankCard) {
        try {
            const normalizedData = await super.normalizeInitData(payload);
            const merchantData: any = await api.payment.getPaymentIntentWithSignature({
                ...normalizedData,
            });

            const data = {
                merchantData: {
                    'paymentIntent': merchantData.payment_intent,
                    'merchant': merchantData.merchant,
                    'signature': merchantData.signature
                },
                styles: payload.styles,
                formParams: payload.formParams

            };
            // @ts-ignore
            const form = PaymentFormSdk.init(data);

            if (!this.isHandlerActive) {
                this.addHandlers(form, payload);
            }
        } catch (e) {
            throw new Error('In initPayment method something going wrong ;(');
        }
    }

    private addHandlers(form: any, payload: InitBankCard) {
        form.on('mounted', (e: any) => {
            // Merchant's code
            payload.readyHandler({
                e,
                solidSdk: form,
            });
        });
        form.on('success', (e: any) => {
            // Event of the success payment processing.
            payload.successHandler({
                 ...e.data,
                'brand': this.brandCard
            });
        });
        form.on('card', (e: any) => {
            // The event informs about the payer card brand. Contains an object with brand information.
            this.brandCard = e.data.card.brand;
            payload.cardHandler(e);
        });
        form.on('verify', (e: any) => {
            // The event informs that the payment starts processing through 3D flow.
            payload.processingHandler(e);
        });
        form.on('fail', (e: any) => {
            // The event informs that the payment has been declined. Contains an object with an error code and error message.

            // Merchant's code
            payload.failHandler(e);
        });
        form.on('error', (e: any) => {
            // Event of any form error after a click on submit button (validation error). Contains an object with a message.
            payload.errorHandler(e);
        });
        form.on('submit', (e: any) => {
            // The event indicates that the user submitted the payment.
            payload.submitHandler(e);
        });
        this.isHandlerActive = true;
    }
}

export default BankCard;
