import React, {useEffect, useState} from 'react';
import cx from 'classnames';

import styles from './CheckBox.module.css';

const CheckBoxIconRounded = ({ checked, className }: any) => (
    <div className={className}>
        {checked ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="11" fill="#4C61C7" stroke="#4C61C7" strokeWidth="2"/>
                <path d="M10.6785 17.8713C11.1212 17.8713 11.4662 17.702 11.7006 17.3439L17.7683 8.06657C17.9376 7.80615 18.0092 7.56527 18.0092 7.3374C18.0092 6.72542 17.5535 6.28271 16.9285 6.28271C16.4988 6.28271 16.2319 6.43896 15.9714 6.84912L10.6459 15.2476L7.94409 11.8947C7.70321 11.6082 7.44279 11.478 7.07821 11.478C6.4467 11.478 5.99097 11.9272 5.99097 12.5392C5.99097 12.8127 6.0756 13.0535 6.30998 13.3205L9.66935 17.3895C9.9493 17.7215 10.2618 17.8713 10.6785 17.8713Z" fill="white"/>
            </svg>
        ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="11" stroke="#4C61C7" strokeWidth="2"/>
            </svg>
        )}
    </div>
);

const CheckBoxIconSquared = ({ checked, className, valid }: any) => {
    const strokeColor = valid ? '#4C61C7' : '#D15760';
    
    return (
        <div className={className}>
            {checked ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="11" fill="#4C61C7" stroke="#4C61C7" />
                    <path d="M10.6782 17.8713C11.1209 17.8713 11.466 17.702 11.7004 17.3439L17.7681 8.06657C17.9373 7.80615 18.009 7.56527 18.009 7.3374C18.009 6.72542 17.5532 6.28271 16.9282 6.28271C16.4985 6.28271 16.2316 6.43896 15.9712 6.84912L10.6457 15.2476L7.94385 11.8947C7.70296 11.6082 7.44255 11.478 7.07796 11.478C6.44645 11.478 5.99072 11.9272 5.99072 12.5392C5.99072 12.8127 6.07536 13.0535 6.30973 13.3205L9.66911 17.3895C9.94906 17.7215 10.2616 17.8713 10.6782 17.8713Z" fill="black"/>
                    <rect x="1" y="1" width="22" height="22" rx="5" fill="#4C61C7" stroke="#4C61C7" />
                    <path d="M10.6875 17.5885C11.1302 17.5885 11.4753 17.4193 11.7096 17.0612L17.7773 7.78385C17.9466 7.52344 18.0182 7.28255 18.0182 7.05469C18.0182 6.44271 17.5625 6 16.9375 6C16.5078 6 16.2409 6.15625 15.9805 6.56641L10.6549 14.9648L7.95312 11.612C7.71224 11.3255 7.45182 11.1953 7.08724 11.1953C6.45573 11.1953 6 11.6445 6 12.2565C6 12.5299 6.08464 12.7708 6.31901 13.0378L9.67839 17.1068C9.95833 17.4388 10.2708 17.5885 10.6875 17.5885Z" fill="#FCFCFC"/>
                </svg>
            ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="22" height="22" rx="5" stroke={strokeColor} strokeWidth="2"/>
                </svg>
            )}
        </div>
    );
};

const CheckBox = ({ name, title, disabled, className, onChange, checked = false, children, centered, squared, valid }: any) => {
    const [isChecked, setChecked] = useState(checked);
    const checkboxId = `checkbox_${name}`;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked: targetChecked } = e.target;

        setChecked(targetChecked);

        if (onChange) onChange(name, targetChecked);
    };

    useEffect(() => {
        setChecked(checked);
    }, [checked]);

    return (
        <div className={cx([styles.container, { [styles.centered]: centered }])}>
            <label>
                <input
                    type="checkbox"
                    id={checkboxId}
                    name={name}
                    disabled={disabled}
                    onChange={handleChange}
                    className={className}
                    checked={isChecked}
                />
                { squared
                    ? <CheckBoxIconSquared className={styles.icon} checked={isChecked} valid={valid} />
                    : <CheckBoxIconRounded className={styles.icon} checked={isChecked} />
                }
                { title && <span className={styles.title}>{title}</span>}
                { children && <div className={styles.label}>{children}</div>}
            </label>
        </div>
    );
};

export default CheckBox;
