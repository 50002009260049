import Date from './Date/Date';

const getTimerTime = (params, timerTime = 10) => {
  const key = params?.key;
  const isExistTimer = params?.isExistTimer;

  const dateFrom = Date.getTime();
  const dateTo = Date.addMinutes(timerTime);

  const timerKey = key ? `timerTime-${key}` : 'timerTime';

  const timerKeyStorage = localStorage.getItem(timerKey);

  if (isExistTimer) {
    return getDifferent(dateFrom, isExistTimer);
  }

  /* Reset timer after 3h 45m */
  if (timerKeyStorage) {
    const dateToClear = Date.getTime(timerKeyStorage).add(45, 'minutes').add(3, 'hours').toDate();
    const dateDiffClear = getDifferent(dateFrom, dateToClear);

    if (dateDiffClear.seconds <= 0) {
      localStorage.removeItem(timerKey);
    }
  }

  if (localStorage.getItem(timerKey)) {
    return getDifferent(dateFrom, localStorage.getItem(timerKey));
  } else {
    localStorage.setItem(timerKey, dateTo);

    return getDifferent(dateFrom, dateTo);
  }
};

export const getDifferent = (dateFrom, dateTo) => Date.getDiff(dateFrom, dateTo);

export default getTimerTime;
