import { useEffect, useState } from 'react';
import useOnboardingFlowForProgress from 'hooks/useOnboardingFlowForProgress';

const blockKey = '_block_';

type DataSegment = {
  index: number;
  interval: number;
}

export const useProgressValue = () => {
  const { step, totalSteps, onboardingFlow, progressConfig: config } = useOnboardingFlowForProgress();

  const dots = config.sections.length + 1;
  const multiplier = 1 / (dots - 1);
  const [progress, setProgress] = useState(0);

  const progressData = onboardingFlow.reduce((acc: DataSegment[], res, index, array) => {
    const prevIndex = index - 1;

    if (res.id.includes(blockKey)) {
      if (acc.length === 0) {
        return [{ index: prevIndex, interval: index }];
      }

      return [
        ...acc,
        {
          index: prevIndex, interval: index - acc[acc.length - 1].index - 1,
        },
      ];
    }

    if (array.length - 1 === index) {
      return [
        ...acc,
        {
          index, interval: index - acc[acc.length - 1].index,
        },
      ];
    }

    return acc;
  }, []);

  useEffect(() => {
    let segmentIndex = -1;
    let segmentStart = 0;
    let segmentEnd = 0;

    for (let i = 0; i < progressData.length; i++) {
      segmentStart = segmentEnd;
      segmentEnd += progressData[i].interval;

      if (step >= segmentStart && step <= segmentEnd) {
        segmentIndex = i;
        break;
      }
    }

    let progressWithinSegment = 0;
    if (segmentIndex !== -1) {
      const segmentProgress = step - segmentStart;
      const segmentTotal = segmentEnd - segmentStart;

      // Ensure that progress reaches 1 on the last page of the last segment
      if (segmentIndex === progressData.length - 1 && step === totalSteps) {
        setProgress(1);

        return;
      } else {
        const segmentFraction = (multiplier - 0.01) / segmentTotal;
        progressWithinSegment = segmentProgress * segmentFraction;
      }
    }

    const result = segmentIndex === -1 ? 0 : (segmentIndex * multiplier) + progressWithinSegment;
    setProgress(result);
  }, [step, totalSteps, onboardingFlow]);

  return progress;
};
