import ApiClient from './apiClient';
import PaymentApi from './Payment';

// types
import { IOnErrorData } from './types/api';

export default function () {
    const errorHandlers: Array<(data: any) => void> = [];

    const onError = (fn: (errorData: IOnErrorData) => void) => {
        errorHandlers.push(fn);
    };

    const handleError = (errorData: IOnErrorData) => {
        errorHandlers.forEach(errorHandler => errorHandler(errorData));
    };

    const api = new ApiClient({ onError: handleError });

    return {
        apiClient: api,
        payment: new PaymentApi({ apiClient: api }),
        onError,
    };
}
