import { useSelector } from 'react-redux';
import { selectCurrentProduct } from 'redux/Payment/selectors';
import { useEffect } from 'react';

interface IUseSolidPaymentPreInit {
  initPayPal: () => void;
  initPaymentGenerator: () => void;
}

function useSolidPaymentPreInit({ initPayPal, initPaymentGenerator }: IUseSolidPaymentPreInit) {
  const currentProduct = useSelector(selectCurrentProduct);

  // const currentProductPrev = useRef<object | string | null>(null);

  useEffect(() => {
    // @ts-ignore
    if (document.getElementById('paypal-button') /* && currentProduct?.id !== currentProductPrev.current?.id */) {
      initPayPal();
      initPaymentGenerator();

      // currentProductPrev.current = currentProduct;
    }
  }, [currentProduct]);
}

export default useSolidPaymentPreInit;
