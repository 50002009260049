import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Text } from 'libComponents';

import styles from './Option.module.css';

import { IOnChangeOption } from 'types/pages/commonTypes';

const RightCheckBox = ({ checked }: any) => (
  <div className={styles.styledCheckbox}>
    {checked ? (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='1' y='1' width='22' height='22' rx='5' fill='#4C61C7' stroke='#4C61C7' strokeWidth='2' />
        <path
          d='M10.6875 17.5885C11.1302 17.5885 11.4753 17.4193 11.7096 17.0612L17.7773 7.78385C17.9466 7.52344 18.0182 7.28255 18.0182 7.05469C18.0182 6.44271 17.5625 6 16.9375 6C16.5078 6 16.2409 6.15625 15.9805 6.56641L10.6549 14.9648L7.95312 11.612C7.71224 11.3255 7.45182 11.1953 7.08724 11.1953C6.45573 11.1953 6 11.6445 6 12.2565C6 12.5299 6.08464 12.7708 6.31901 13.0378L9.67839 17.1068C9.95833 17.4388 10.2708 17.5885 10.6875 17.5885Z'
          fill='#FCFCFC' />
      </svg>
    ) : (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='1' y='1' width='22' height='22' rx='5' stroke='#4C61C7' strokeWidth='2' />
      </svg>
    )}
  </div>
);

const Option = ({ title, subTitle, onClick, rightCheckbox, className, value: inVal, isActiveItem, disabled = false, Icon, withIcon }: any) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => setChecked(isActiveItem), [isActiveItem]);

  const onChange = ({ target: { value } }: IOnChangeOption) => {
    setChecked(!checked);

    if (onClick) onClick(value, !checked);
  };

  return (
    <div className={cx(styles.container, className)}>
      <input type='checkbox' onChange={onChange} className={styles.checkbox} value={inVal} disabled={disabled} />
      <div className={cx(styles.option, { [styles.checked]: checked })}>
        {withIcon && (
          <div className={styles.icon}><Icon /></div>
        )}
        <div className={styles.titleContainer}>
          <Text className={cx({ [styles.active]: checked })}>{title}</Text>
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        </div>
        {rightCheckbox && (<RightCheckBox checked={checked} />)}
      </div>
    </div>
  );
};

export default Option;
